import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {globalAppSettings} from "../../config/globalAppSettings";
import {login as loginApi, logout as userLogout} from "../../api/UsersApi";
import {IDLE, FAILED, LOADING, SUCCEEDED} from "../storeStates";

export const login = createAsyncThunk("auth/login", async (data,{rejectWithValue}) => {
    // Make API call to authenticate user
    const response = await loginApi(data)
    const loginData = await response.json()
    console.log(loginData)
    if (loginData.role !== 'admin') {
        return rejectWithValue('You are not the admin.');
    }
    const token = await loginData.token

    if (token) {
        window.location.reload()
        globalAppSettings.auth.localStorageKey = 'token'
        localStorage.setItem(globalAppSettings.auth.localStorageKey, token)
        return token
    } else {
       return rejectWithValue(loginData.message)
    }
});

export const logout = createAsyncThunk("auth/logout", async () => {
    await userLogout();
});

const initialValues = {
    isLoggedIn: false,
    token: null,
    isLoading: false,
    error: null,
    status: IDLE,
}

const authSlice = createSlice({
    name: 'Auth',
    initialState: initialValues,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(login.pending, (state) => {
                state.isLoading = true;
                state.isLoggedIn = false
                state.error = null;
                state.status = LOADING
            })
            .addCase(login.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isLoggedIn = true
                state.token = action.payload;
                state.status = SUCCEEDED
                state.error = null
            })
            .addCase(login.rejected, (state, action) => {
                state.isLoading = false;
                state.isLoggedIn = false
                state.error = action.payload;
                state.status = FAILED
            }).addCase(logout.fulfilled,  (state, action) => {
                state.isLoggedIn = false;
        })
    }
})

export const {setIsLoggedIn, setToken, setLogut} = authSlice.actions
export const getToken = (state) => state.auth.token
export const getAuthError = (state) => state.auth.error;
export const getAuthStatus = (state) => state.auth.status
export const getLoggedInStatus = (state) => state.auth.isLoggedIn; // defined in configureStore({reducer: {userSearchForm}});
export default authSlice.reducer
