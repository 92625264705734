import {metaModel} from "../config/metaModel";
import {Helmet} from "react-helmet";

const HomePage = () => {
  metaModel.title = 'Home Page'
  metaModel.description = "This is the home page of the application"
  metaModel.keywords = 'This is the text for meta keyword'
  return <>
    <Helmet>
      <title> {metaModel.title}</title>
      <meta name="description" content={metaModel.description}/>
      <meta name="keywords" content={metaModel.keywords}/>
    </Helmet>
    <h1>HomePage</h1>
  </>
}

export default HomePage