import { API_URL } from "../config/env";
const reviews = '/reviews'
const review = '/review'
const token = localStorage.getItem('token')

export const getReviews = async (queryData) => {
    const url = `${API_URL}${reviews}${queryData}`;
    console.log("Calling url: ", url)
    try {
        const response = await fetch(url, {
            headers: {
                "Content-Type": "application/json",
                Authorization: 'Bearer ' + token
            }
        });
        return response;
    } catch (error) {
        console.error("Failed calling backend searchUser: ", url, "Error:", error)
        throw error;
    }
};

export const editReview = async (id, userData) => {
    const url = `${API_URL}${review}/${id}`;
    console.log("Calling url: ", url, "BODY:", JSON.stringify(userData))
    try {
        const response = await fetch(url, {
            method: 'PUT',
            body: JSON.stringify(userData),
            headers: {
                "Content-Type": "application/json",
                Authorization: 'Bearer ' + token
            }
        });
        return response;
    } catch (error) {
        console.error("Failed calling backend editUser: ", url, "Error:", error)
        throw error;
    }
};

export const deleteReview = async (id) => {
    const url = `${API_URL}${review}/${id}`;
    console.log("Calling url: ", url)
    try {
        const response = await fetch(url, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        })
        const data = await response;
        return await data;
    } catch (error) {
        console.error("Failed calling backend deleteUser: ", url, "Error:", error)
        throw error;
    }
};

export const addReview = async (userData) => {
    const url = `${API_URL}${review}`;
    console.log("Calling url: ", url, "BODY:", JSON.stringify(userData))
    try {
        const response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(userData),
            headers: {
                "Content-Type": "application/json",
                Authorization: 'Bearer ' + token
            }
        });
        return response;
    } catch (error) {
        console.error("Failed calling backend addUser: ", url, "Error:", error)
        throw error;
    }
};

export const deleteReviw = async (id) => {
    const url = `${API_URL}${review}/${id}`;
    console.log("Calling url: ", url)
    try {
        const response = await fetch(url, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        })
        const data = await response;
        return await data;
    } catch (error) {
        console.error("Failed calling backend deleteUser: ", url, "Error:", error)
        throw error;
    }
};

