import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { addUser, deleteUser, editUser, searchUser } from "../../api/UsersApi";
import { USED_BY_POPUP, USED_BY_SEARCH } from "./userStoreConst";
import { FAILED, IDLE, LOADING, SUCCEEDED } from "../storeStates";

export const asyncSearchUser = createAsyncThunk('asyncSearchUser', async (queryData, {rejectWithValue}) => {
  const response = await searchUser(queryData)
  const data = await response.json();
  if (!response.ok) {
    console.error("Response not ok.", response)
    return rejectWithValue(response.statusText)
  } else {
    return await data
  }
});

export const asyncDeleteUser = createAsyncThunk('asyncDeleteUser', async (userId, {rejectWithValue}) => {
  const response = await deleteUser(userId);
  if (!response.ok) {
    console.error("Response not ok.", response)
    return rejectWithValue(response.statusText)
  } else {
    return userId
  }
});

export const asyncEditUser = createAsyncThunk('asyncEditUser', async (user, {rejectWithValue}) => {
  const {id, dataUpdated} = user
  const response = await editUser(id, dataUpdated)
  if (!response.ok) {
    console.error("Response not ok.", response)
    return rejectWithValue(response.statusText)
  } else {
    return await response.json()
  }
});

export const asyncAddUser = createAsyncThunk('asyncAddUser', async (user, {rejectWithValue}) => {
  const response = await addUser(user);
  if (!response.ok) {
    console.error("Response not ok.", response)
    return rejectWithValue(response.statusText)
  } else {
    return await response.json()
  }
});

const userSlice = createSlice({
  name: 'users',
  initialState: {
    data: [],
    status: IDLE,
    error: null,
    usedBy: '' //usedBy
  },
  reducers: {
    setUsedBySearchForm: (state, action) => {
      state.usedBy = USED_BY_SEARCH
    },
    setUsedByPopup: (state, action) => {
      state.usedBy = USED_BY_POPUP
    },
    resetUsedBy: (state, action) => {
      state.error = null
      state.status = 'idle'
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(asyncSearchUser.pending, (state) => {
        state.status = LOADING;
      })
      .addCase(asyncSearchUser.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(asyncSearchUser.rejected, (state, action) => {
        state.status = FAILED;
        state.error = action.payload;
      })
      .addCase(asyncDeleteUser.pending, (state) => {
        state.status = LOADING;
      })
      .addCase(asyncDeleteUser.fulfilled, (state, action) => {
        state.status = SUCCEEDED;
        state.data = state.data.filter((user) => user._id !== action.payload);
      })
      .addCase(asyncDeleteUser.rejected, (state, action) => {
        state.status = FAILED;
        state.error = action.payload;
      })
      .addCase(asyncEditUser.pending, (state) => {
        state.status = LOADING;
      })
      .addCase(asyncEditUser.fulfilled, (state, action) => {
        state.status = SUCCEEDED;
        const updatedUser = action.payload;
        const index = state.data.findIndex((user) => user._id === updatedUser._id);
        if (index !== -1) {
          state.data.splice(index, 1, updatedUser);
        }
      })
      .addCase(asyncEditUser.rejected, (state, action) => {
        state.status = FAILED;
        console.log(action)
        state.error = action.payload;
      })
      .addCase(asyncAddUser.pending, (state) => {
        state.status = LOADING;
      })
      .addCase(asyncAddUser.fulfilled, (state, action) => {
        state.status = SUCCEEDED;
        state.data.push(action.payload);
      })
      .addCase(asyncAddUser.rejected, (state, action) => {
        state.status = FAILED;
        state.error = action.payload;
      });
  },
});

export const {setUsedByPopup, setUsedBySearchForm, resetUsedBy} = userSlice.actions;
export const getAllUsers = (state) => state.users.data; // defined in configureStore({reducer: {users}});
export const getStatus = (state) => state.users.status;
export const getError = (state) => state.users.error;
export const getUsedBy = (state) => state.users.usedBy
export default userSlice.reducer;