import TableRow from "@mui/material/TableRow/index";
import { styled } from "@mui/material/styles/index";
import TableCell, { tableCellClasses } from "@mui/material/TableCell/index";

const StyledTableCell = styled(TableCell)(({theme}) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#3f51b5',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({theme}) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
  height: '100px',

}));

export {
  StyledTableCell,
  StyledTableRow
}