import { useEffect, useState } from "react";
import { Backdrop, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { DeleteForever } from "@mui/icons-material";
import { useDispatch, useSelector } from 'react-redux';
import {
    asyncDeleteUser,
    getAllUsers,
    getStatus,
    getUsedBy,
    resetUsedBy,
    setUsedByPopup
} from '../../store/users/usersSlice';
import { FAILED, LOADING, SUCCEEDED } from "../../store/storeStates";
import {USED_BY_POPUP} from "../../store/users/userStoreConst";
import { CircularProgress } from "@material-ui/core";
import {resetForm} from "../../store/users/userDetailsPopupSlice";
import {
    asyncDeleteReview,
    getAllReviews,
    getReviewsStatus, getReviewsUsedBy, resetReviewsUsedBy,
    setReviewsUsedByPopu
} from "../../store/reviews/reviewsSlice";
import {resetReviewForm} from "../../store/reviews/reviewDetailsPopupSlice";

const ConfirmDeletePopup = ({open, data, onClose}) => {
    const [deleteUser, setDeleteUser] = useState(false)
    const dispatch = useDispatch();
    const reviewsStatus = useSelector(getReviewsStatus)
    const usedBy = useSelector(getReviewsUsedBy)

    useEffect(() => {
        if (deleteUser) {
            dispatch(asyncDeleteReview(data._id))
            dispatch(setReviewsUsedByPopu())
            setDeleteUser(false)
        }
    }, [deleteUser])

    useEffect(() => {
        if (reviewsStatus === SUCCEEDED) {
            closeHandler()
        }
    }, [reviewsStatus])

    const deleteHandler = () => {
        setDeleteUser(true)
    }

    const closeHandler = () => {
        onClose()
        dispatch(resetReviewForm())
        dispatch(resetReviewsUsedBy())
    }

    return (
        <>
            <Dialog open={open} onClose={closeHandler} BackdropComponent={Backdrop} BackdropProps={{invisible: true}}>
                <DialogTitle>Delete Review</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete the user {data.description} {data.id}?
                    </DialogContentText>
                </DialogContent>
                {reviewsStatus === LOADING && usedBy === USED_BY_POPUP && <CircularProgress/>}
                <DialogActions>
                    <Button onClick={closeHandler}>Cancel</Button>
                    <Button variant="contained" startIcon={<DeleteForever/>} onClick={deleteHandler}>Delete</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default ConfirmDeletePopup