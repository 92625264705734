import {useEffect} from "react";
import {useSelector} from 'react-redux'
import toastr from "toastr";
import {Helmet} from 'react-helmet';
import SearchForm from "../compontents/users/SearchForm";
import UsersTable from "../compontents/users/UsersTable";
import {FAILED, SUCCEEDED} from "../store/storeStates";
import {getError, getStatus} from "../store/users/usersSlice";
import {metaModel} from "../config/metaModel";
import ShopsTable from "../compontents/shops/ShopsTable";
import {getShopError, getShopStatus} from "../store/shops/shopsSlice";

const ShopsPage = () => {
    const shopStatus = useSelector(getShopStatus)
    const shopError = useSelector(getShopError)

    useEffect(() => {
        if (shopStatus === SUCCEEDED) {
            toastr.info("Operation completed successfully")
        } else if (shopStatus === FAILED) {
            toastr.error("Error: " + shopError)
        }
    }, [shopStatus])

    metaModel.title = 'Shops Page'
    metaModel.description = "This page has all users of the application"
    metaModel.keywords = 'This is the text for meta keyword'

    return (
        <>
            <Helmet>
                <title> {metaModel.title}</title>
                <meta name="description" content={metaModel.description}/>
                <meta name="keywords" content={metaModel.keywords}/>
            </Helmet>
            <h1>The Shop Page</h1>
            <ShopsTable/>
        </>
    )
}

export default ShopsPage