import { useEffect, useState } from "react";
import { Backdrop, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { DeleteForever } from "@mui/icons-material";
import { useDispatch, useSelector } from 'react-redux';
import { FAILED, LOADING, SUCCEEDED } from "../../store/storeStates";
import {USED_BY_POPUP} from "../../store/users/userStoreConst";
import { CircularProgress } from "@material-ui/core";
import {
    asyncDeleteShop,
    getAllShops,
    getShopStatus,
    getShopUsedBy, resetShopUsedBy,
    setUsedByShopPopup
} from "../../store/shops/shopsSlice";
import {resetShopForm} from "../../store/shops/shopDetailsPopupSlice";

const ConfirmDeletePopup = ({open, data, onClose}) => {
    const [deleteUser, setDeleteUser] = useState(false)
    const dispatch = useDispatch();
    const shops = useSelector(getAllShops)
    const shopsStatus = useSelector(getShopStatus)
    const usedBy = useSelector(getShopUsedBy)

    useEffect(() => {
        if (deleteUser) {
            dispatch(asyncDeleteShop(data._id))
            dispatch(setUsedByShopPopup())
            setDeleteUser(false)
        }
    }, [deleteUser, shops.status])

    useEffect(() => {
        if (shopsStatus === SUCCEEDED) {
            closeHandler()
        }
    }, [shopsStatus])

    const deleteHandler = () => {
        setDeleteUser(true)
    }

    const closeHandler = () => {
        onClose()
        dispatch(resetShopForm())
        dispatch(resetShopUsedBy())
    }

    return (
        <>
            <Dialog open={open} onClose={closeHandler} BackdropComponent={Backdrop} BackdropProps={{invisible: true}}>
                <DialogTitle>Delete shop</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete the shop {data.name} {data.address}?
                    </DialogContentText>
                </DialogContent>
                {shopsStatus === LOADING && usedBy === USED_BY_POPUP && <CircularProgress/>}
                <DialogActions>
                    <Button onClick={closeHandler}>Cancel</Button>
                    <Button variant="contained" startIcon={<DeleteForever/>} onClick={deleteHandler}>Delete</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default ConfirmDeletePopup