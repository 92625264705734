import { createSlice } from '@reduxjs/toolkit'

const initialValues = {
    id: '',
    description: '',
    rank: '',
    userId: '',
    simpleId: '',
    active: ' ',
    createdFrom: '',
    createdTo: '',
    updatedFrom: '',
    updatedTo: '',
}

const reviewSearchFormSlice = createSlice({
    name: 'form',
    initialState: initialValues,
    reducers: {
        setReviewDescriptionSearchForm: (state, action) => {
            state.description = action.payload
        },
        setReviewRankSearchForm: (state, action) => {
            state.rank = action.payload
        },
        setReviewUserIdSearchForm: (state, action) => {
            state.userId = action.payload
        },
        setReviewSimpleId: (state, action) => {
          state.simpleId = action.payload
        },
        setReviewCreatedFromSearchForm: (state, action) => {
            state.createdFrom = action.payload
        },
        setReviewUpdatedFromSearchForm: (state, action) => {
            state.updatedFrom = action.payload
        },
        setReviewCreatedToSearchForm: (state, action) => {
            state.createdTo = action.payload
        },
        setReviewUpdatedToSearchForm: (state, action) => {
            state.updatedTo = action.payload
        },
        setReviewActiveSearchForm: (state, action) => {
            state.active = action.payload
        },
        setReviewIdSearchForm: (state, action) => {
            state.id = action.payload
        },
        resetReviewSearchForm: (state) => initialValues
    }
})

export const {setReviewCreatedToSearchForm, resetReviewSearchForm, setReviewActiveSearchForm, setReviewCreatedFromSearchForm, setReviewDescriptionSearchForm,
    setReviewRankSearchForm, setReviewIdSearchForm, setReviewUpdatedFromSearchForm, setReviewUpdatedToSearchForm, setReviewUserIdSearchForm, setReviewSimpleId} = reviewSearchFormSlice.actions
export const getReviewSearchForm = (state) => state.reviewSearchForm; // defined in configureStore({reducer: {userSearchForm}});
export default reviewSearchFormSlice.reducer
