import {makeStyles} from "@material-ui/core/styles";
import {Grid} from "@mui/material";
import Typography from "@material-ui/core/Typography";
import React from "react";

const allElements = makeStyles((theme) => ({
    totalContainer: {
        marginTop: theme.spacing(4),
    },
    totalLabel: {
        color: theme.palette.primary.main,
        fontSize: '1.5rem',
        fontWeight: 'bold',
        textAlign: 'right',
    },
    totalValue: {
        color: theme.palette.primary.main,
        fontSize: '2rem',
        fontWeight: 'bold',
        borderBottom: `3px solid ${theme.palette.primary.main}`,
        paddingBottom: theme.spacing(0.5),
        textAlign: 'right',
    },
}));

const AllElements = ({count}) => {
    const classes = allElements();

    return (
        <Grid container className={classes.totalContainer}>
            <Grid item xs={12} sm={6} md={9}></Grid>
            <Grid item xs={12} sm={6} md={3}>
                <Typography variant="h4" className={classes.totalValue}>
                    Total: {count}
                </Typography>
            </Grid>
        </Grid>
    )
}

export default AllElements;