import { useEffect, useState } from "react";
import { Backdrop, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { DeleteForever } from "@mui/icons-material";
import { useDispatch, useSelector } from 'react-redux';
import {
  asyncDeleteUser,
  getAllUsers,
  getStatus,
  getUsedBy,
  resetUsedBy,
  setUsedByPopup
} from '../../store/users/usersSlice';
import { FAILED, LOADING, SUCCEEDED } from "../../store/storeStates";
import {USED_BY_POPUP} from "../../store/users/userStoreConst";
import { CircularProgress } from "@material-ui/core";
import {resetForm} from "../../store/users/userDetailsPopupSlice";

const ConfirmDeletePopup = ({open, data, onClose}) => {
  const [deleteUser, setDeleteUser] = useState(false)
  const dispatch = useDispatch();
  const users = useSelector(getAllUsers)
  const usersStatus = useSelector(getStatus)
  const usedBy = useSelector(getUsedBy)

  useEffect(() => {
    if (deleteUser) {
      dispatch(asyncDeleteUser(data._id))
      dispatch(setUsedByPopup())
      setDeleteUser(false)
    }
  }, [deleteUser, users.status])

  useEffect(() => {
    if (usersStatus === SUCCEEDED) {
      closeHandler()
    }
  }, [usersStatus])

  const deleteHandler = () => {
    setDeleteUser(true)
  }

  const closeHandler = () => {
    onClose()
    dispatch(resetForm())
    dispatch(resetUsedBy())
  }

  return (
    <>
      <Dialog open={open} onClose={closeHandler} BackdropComponent={Backdrop} BackdropProps={{invisible: true}}>
        <DialogTitle>Delete User</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the user {data.name} {data.surname}?
          </DialogContentText>
        </DialogContent>
        {usersStatus === LOADING && usedBy === USED_BY_POPUP && <CircularProgress/>}
        <DialogActions>
          <Button onClick={closeHandler}>Cancel</Button>
          <Button variant="contained" startIcon={<DeleteForever/>} onClick={deleteHandler}>Delete</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ConfirmDeletePopup