import { API_URL } from "../config/env";
const shops = '/shops'
const shop = '/shop'
const token = localStorage.getItem('token')

export const getShops = async () => {
    const url = `${API_URL}${shops}`;
    try {
        const response = await fetch(url, {
            headers: {
                "Content-Type": "application/json",
                Authorization: 'Bearer ' + token
            }
        });
        return response;
    } catch (error) {
        console.error("Failed calling backend editUser: ", url, "Error:", error)
        throw error;
    }
};

export const editShop = async (id, userData) => {
    const url = `${API_URL}${shops}/${id}`;
    console.log("Calling url: ", url, "BODY:", JSON.stringify(userData))
    try {
        const response = await fetch(url, {
            method: 'PUT',
            body: JSON.stringify(userData),
            headers: {
                "Content-Type": "application/json",
                Authorization: 'Bearer ' + token
            }
        });
        return response;
    } catch (error) {
        console.error("Failed calling backend editUser: ", url, "Error:", error)
        throw error;
    }
};

export const deleteShop = async (id) => {
    const url = `${API_URL}${shops}/${id}`;
    console.log("Calling url: ", url)
    try {
        const response = await fetch(url, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        })
        const data = await response;
        return await data;
    } catch (error) {
        console.error("Failed calling backend deleteUser: ", url, "Error:", error)
        throw error;
    }
};

export const addShop = async (shopData) => {
    const url = `${API_URL}${shops}`;
    console.log("Calling url: ", url, "BODY:", JSON.stringify(shopData))
    try {
        const response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(shopData),
            headers: {
                "Content-Type": "application/json",
                Authorization: 'Bearer ' + token
            }
        });
        return response;
    } catch (error) {
        console.error("Failed calling backend addUser: ", url, "Error:", error)
        throw error;
    }
};

