import { createSlice } from '@reduxjs/toolkit'

const initialValues = {
  id: '',
  name: '',
  surname: '',
  email: '',
  phone: '',
  role: ' ',
  active: ' ',
  locked: ' ',
  emailVerified: ' ',
  createdFrom: '',
  createdTo: '',
  updatedFrom: '',
  updatedTo: '',
}

const userSearchFormSlice = createSlice({
  name: 'form',
  initialState: initialValues,
  reducers: {
    setNameSearchForm: (state, action) => {
      state.name = action.payload
    },
    setSurnameSearchForm: (state, action) => {
      state.surname = action.payload
    },
    setEmailSearchForm: (state, action) => {
      state.email = action.payload
    },
    setPhoneSearchForm: (state, action) => {
      state.phone = action.payload
    },
    setRoleSearchForm: (state, action) => {
      state.role = action.payload
    },
    setVerifiedSearchForm: (state, action) => {
      state.emailVerified = action.payload
    },
    setLockedSearchForm: (state, action) => {
      state.locked = action.payload
    },
    setCreatedFromSearchForm: (state, action) => {
      state.createdFrom = action.payload
    },
    setUpdatedFromSearchForm: (state, action) => {
      state.updatedFrom = action.payload
    },
    setCreatedToSearchForm: (state, action) => {
      state.createdTo = action.payload
    },
    setUpdatedToSearchForm: (state, action) => {
      state.updatedTo = action.payload
    },
    setActiveSearchForm: (state, action) => {
      state.active = action.payload
    },
    setIdSearchForm: (state, action) => {
      state.id = action.payload
    },
    resetSearchForm: (state) => initialValues
  }
})

export const {setNameSearchForm, setIdSearchForm, setPhoneSearchForm, setSurnameSearchForm, setRoleSearchForm,
  setEmailSearchForm,resetSearchForm, setActiveSearchForm, setUpdatedToSearchForm, setCreatedToSearchForm,
  setUpdatedFromSearchForm, setCreatedFromSearchForm, setLockedSearchForm, setVerifiedSearchForm } = userSearchFormSlice.actions
export const getSearchForm = (state) => state.userSearchForm; // defined in configureStore({reducer: {userSearchForm}});
export default userSearchFormSlice.reducer
