import React, { useEffect, useState } from 'react';

import moment from 'moment'
import { Box, Button } from '@mui/material';
import { Add, DeleteForever, Edit, Pageview } from "@mui/icons-material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import UserDetailsPopup from "./UserDetailsPopup";
import TablePagination from "@material-ui/core/TablePagination";
import { CircularProgress, TableSortLabel } from "@material-ui/core";
import { useSelector } from 'react-redux';
import ConfirmDeletePopup from "./ConfirmDeletePopup";
import {Checkbox} from "@mui/material";
import { StyledTableCell, StyledTableRow } from "../_common/TableStyle";
import { USED_BY_SEARCH } from "../../store/users/userStoreConst";
import { LOADING } from "../../store/storeStates";
import { ADD, EDIT, VIEW } from "../../config/PopupType";
import { getAllUsers, getStatus, getUsedBy } from '../../store/users/usersSlice';
import AllElements from "../_common/AllElements";

const UsersTable = () => {
  const [showUserDetailsPopup, setShowUserDetailsPopup] = useState(false)
  const [popupType, setPopupType] = useState('')
  const [showConfirmDeletePopup, setConfirmDeletePopup] = useState(false)

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(3);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');

  const users = useSelector(getAllUsers)
  const usersStatus = useSelector(getStatus)
  const usedBy = useSelector(getUsedBy)
  const [usersTable, setUsersTable] = useState([]) // we can remove this. ofr now is serving only for ordering
  const [selectedUser, setSelectedUser] = useState({})

  useEffect(() => {
    // copy of the users present in the state. This is only to be able to order by in client side.
    // the search API will return from backend the fowwloign json
    // {
    //     page: "the actual page selected to show"
    //     count: "nr of records per page"
    //     listOfUsers: "list to show in the table (if the db table contains 1000000 records and count is 5, while page is 12. The table will render only 5 records."
    // }
    // use this for server side order and pagination  https://dev.to/stephanieopala/server-side-pagination-in-react-js-material-ui-3bk
    setUsersTable(users.users)
  }, [users])

  const onShowUserClick = (row) => showDataOnPopup(row, VIEW)
  const onEditUserClick = (row) => showDataOnPopup(row, EDIT)
  const onAddUserClick = () => showDataOnPopup({}, ADD)

  function showDataOnPopup(selectedUserToShow, popupType) {
    setSelectedUser(selectedUserToShow)
    setPopupType(popupType)
    setShowUserDetailsPopup(true);
  }

  const onCloseUserDetailsPopup = () => setShowUserDetailsPopup(false)

  const onDeleteUserIcon = (row) => {
    setSelectedUser(row)
    setConfirmDeletePopup(true);
  };

  const onCloseConfirmDeletePopup = () => setConfirmDeletePopup(false)

  //will be replaced by MUI
  const onPageChange = (event, newPage) => {
    setPage(newPage);
  };

  //will be replaced by MUI
  const onRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //will be replaced by MUI
  const handleSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);

    const copyOfDataUsers = [...usersTable]
    let sorted = copyOfDataUsers.sort((a, b) => {
      if (a[property] !== undefined && b[property] !== undefined) {
        if (isAsc) {
          return a[property].toString().localeCompare(b[property].toString());
        } else {
          return b[property].toString().localeCompare(a[property].toString());
        }
      }
    });
    setUsersTable(sorted);
  };

  return (
    <>
      <UserDetailsPopup open={showUserDetailsPopup} data={selectedUser} onClose={onCloseUserDetailsPopup} popupType={popupType}/>
      <ConfirmDeletePopup open={showConfirmDeletePopup} data={selectedUser} onClose={onCloseConfirmDeletePopup}/>
      {/*<ErrorPopup open={showErrorPopup} error={errorMessage} onClose={onCloseErrorPopup} />*/}

      <Box sx={{padding: '2rem'}} className="tableResult">
        <Button onClick={onAddUserClick} startIcon={<Add/>}> Add User </Button>
        {(usersStatus === LOADING && usedBy === USED_BY_SEARCH) && <CircularProgress/>}
        <TableContainer component={Paper}>
          <Table sx={{minWidth: 700}} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="right">
                  <TableSortLabel style={{color: 'white'}} active={orderBy === '_id'}
                                  direction={order}
                                  onClick={() => handleSort('_id')}>ID</TableSortLabel>
                </StyledTableCell>
                <StyledTableCell align="right">
                  <TableSortLabel style={{color: 'white'}} active={orderBy === 'name'}
                                  direction={order}
                                  onClick={() => handleSort('name')}>Name</TableSortLabel>
                </StyledTableCell>
                <StyledTableCell align="right">
                  <TableSortLabel style={{color: 'white'}} active={orderBy === 'surname'}
                                  direction={order}
                                  onClick={() => handleSort('surname')}>Surname</TableSortLabel>
                </StyledTableCell>
                <StyledTableCell align="right">
                  <TableSortLabel style={{color: 'white'}} active={orderBy === 'email'}
                                  direction={order}
                                  onClick={() => handleSort('email')}>Email</TableSortLabel>
                </StyledTableCell>
                <StyledTableCell align="right">
                  <TableSortLabel style={{color: 'white'}} active={orderBy === 'phone'}
                                  direction={order}
                                  onClick={() => handleSort('phone')}>Phone</TableSortLabel>
                </StyledTableCell>
                <StyledTableCell align="right">
                  <TableSortLabel style={{color: 'white'}} active={orderBy === 'role'}
                                  direction={order}
                                  onClick={() => handleSort('role')}>Role</TableSortLabel>
                </StyledTableCell>
                <StyledTableCell align="right">
                  <TableSortLabel style={{color: 'white'}} active={orderBy === 'active'}
                                  direction={order}
                                  onClick={() => handleSort('active')}>Active</TableSortLabel>
                  </StyledTableCell>
                <StyledTableCell align="right">
                  <TableSortLabel style={{color: 'white'}} active={orderBy === 'verified'}
                                  direction={order}
                                  onClick={() => handleSort('verified')}>Verified </TableSortLabel>
                  </StyledTableCell>
                <StyledTableCell align="right">
                  <TableSortLabel style={{color: 'white'}} active={orderBy === 'failedLoginCount'}
                                  direction={order}
                                  onClick={() => handleSort('failedLoginCount')}>Failed Login</TableSortLabel>
                  </StyledTableCell>
                <StyledTableCell align="right">
                  <TableSortLabel style={{color: 'white'}} active={orderBy === 'locked'}
                                                                direction={order}
                                                                onClick={() => handleSort('locked')}>Locked
                  </TableSortLabel>
                  </StyledTableCell>
                <StyledTableCell align="right">
                  <TableSortLabel style={{color: 'white'}} active={orderBy === 'createdAt'}
                                  direction={order}
                                  onClick={() => handleSort('createdAt')}>Created</TableSortLabel>
                </StyledTableCell>
                <StyledTableCell align="right">Actions</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {usersTable?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                <React.Fragment key={row._id}>
                  <StyledTableRow key={row._id}>
                    <StyledTableCell component="th" scope="row">{row._id}</StyledTableCell>
                    <StyledTableCell align="right">{row.name}</StyledTableCell>
                    <StyledTableCell align="right">{row.surname}</StyledTableCell>
                    <StyledTableCell align="right">{row.email}</StyledTableCell>
                    <StyledTableCell align="right">{row.phone}</StyledTableCell>
                    <StyledTableCell align="right">{row.role}</StyledTableCell>
                    <StyledTableCell align="right">
                      <Checkbox
                        checked={row.active}
                        disabled
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <Checkbox
                          checked={row.emailVerified}
                          disabled
                          inputProps={{ 'aria-label': 'controlled' }}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="right">{row.failedLoginCount}</StyledTableCell>
                    <StyledTableCell align="right">
                      <Checkbox
                          checked={row.locked}
                          disabled
                          inputProps={{ 'aria-label': 'controlled' }}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="right">{moment(row.createdAt).format('YYYY-MM-DD')}</StyledTableCell>
                    <StyledTableCell align='right'>
                      <Button variant="outlined" startIcon={<DeleteForever/>}
                              onClick={() => onDeleteUserIcon(row)}/>
                      <Button variant="outlined" startIcon={<Edit/>}
                              onClick={() => onEditUserClick(row)}/>
                      <Button variant="outlined" startIcon={<Pageview/>}
                              onClick={() => onShowUserClick(row)}/>
                    </StyledTableCell>
                  </StyledTableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
          <TablePagination rowsPerPageOptions={[5, 10, 50, 100]} component="div"
                           count={usersTable ? usersTable.length : 0} rowsPerPage={rowsPerPage}
                           page={page} onPageChange={onPageChange}
                           onRowsPerPageChange={onRowsPerPageChange}/>
        </TableContainer>
        <AllElements count={users.count} />
      </Box>
    </>
  );
};

export default UsersTable;