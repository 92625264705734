import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {addShop, deleteShop, editShop, getShops} from "../../api/ShopApi";
import { FAILED, IDLE, LOADING, SUCCEEDED } from "../storeStates";
import {USED_BY_POPUP, USED_BY_SEARCH} from "../users/userStoreConst";

export const asyncSearchShop = createAsyncThunk('asyncSearchShop', async (queryData, {rejectWithValue}) => {
    const response = await getShops()
    const data = await response.json();
    console.log(data, "DATA")
    if (!response.ok) {
        console.error("Response not ok.", response)
        return rejectWithValue(response.statusText)
    } else {
        return await data
    }
});

export const asyncDeleteShop = createAsyncThunk('asyncDeleteShop', async (userId, {rejectWithValue}) => {
    const response = await deleteShop(userId);
    if (!response.ok) {
        console.error("Response not ok.", response)
        return rejectWithValue(response.statusText)
    } else {
        return userId
    }
});

export const asyncEditShop = createAsyncThunk('asyncEditShop', async (user, {rejectWithValue}) => {
    const {id, dataUpdated} = user
    const response = await editShop(id, dataUpdated)
    if (!response.ok) {
        console.error("Response not ok.", response)
        return rejectWithValue(response.statusText)
    } else {
        return await response.json()
    }
});

export const asyncAddShop = createAsyncThunk('asyncAddShop', async (shopData, {rejectWithValue}) => {
    const response = await addShop(shopData);
    if (!response.ok) {
        console.error("Response not ok.", response)
        return rejectWithValue(response.statusText)
    } else {
        return await response.json()
    }
});

const shopsSlice = createSlice({
    name: 'shops',
    initialState: {
        data: [],
        status: IDLE,
        error: null,
        usedBy: '' //usedBy
    },
    reducers: {
        setUsedByHomeShop: (state, action) => {
            state.usedBy = USED_BY_SEARCH
        },
        setUsedByShopPopup: (state, action) => {
            state.usedBy = USED_BY_POPUP
        },
        resetShopUsedBy: (state, action) => {
            state.error = null
            state.status = 'idle'
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(asyncSearchShop.pending, (state) => {
                state.status = LOADING;
            })
            .addCase(asyncSearchShop.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.data = action.payload;
            })
            .addCase(asyncSearchShop.rejected, (state, action) => {
                state.status = FAILED;
                state.error = action.payload;
            })
            .addCase(asyncDeleteShop.pending, (state) => {
                state.status = LOADING;
            })
            .addCase(asyncDeleteShop.fulfilled, (state, action) => {
                state.status = SUCCEEDED;
                state.data = state.data.filter((user) => user._id !== action.payload);
            })
            .addCase(asyncDeleteShop.rejected, (state, action) => {
                state.status = FAILED;
                state.error = action.payload;
            })
            .addCase(asyncEditShop.pending, (state) => {
                state.status = LOADING;
            })
            .addCase(asyncEditShop.fulfilled, (state, action) => {
                state.status = SUCCEEDED;
                const updatedUser = action.payload;
                const index = state.data.findIndex((user) => user._id === updatedUser._id);
                if (index !== -1) {
                    state.data.splice(index, 1, updatedUser);
                }
            })
            .addCase(asyncEditShop.rejected, (state, action) => {
                state.status = FAILED;
                console.log(action)
                state.error = action.payload;
            })
            .addCase(asyncAddShop.pending, (state) => {
                state.status = LOADING;
            })
            .addCase(asyncAddShop.fulfilled, (state, action) => {
                state.status = SUCCEEDED;
                state.data.push(action.payload);
            })
            .addCase(asyncAddShop.rejected, (state, action) => {
                state.status = FAILED;
                state.error = action.payload;
            });
    },
});

export const {setUsedByShopPopup, setUsedByHomeShop, resetShopUsedBy} = shopsSlice.actions;
export const getAllShops = (state) => state.shops.data; // defined in configureStore({reducer: {users}});
export const getShopStatus = (state) => state.shops.status;
export const getShopError = (state) => state.shops.error;
export const getShopUsedBy = (state) => state.shops.usedBy
export default shopsSlice.reducer;