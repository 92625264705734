import {useRouteError} from 'react-router-dom'
import PageContent from "../compontents/errorContent/PageContent";


function ErrorEventPage() {
    const error = useRouteError()

    let title = 'An error occurred'
    let message = 'Something went wrong'

    if (error.status === 500) {
        message = error.data.message
    }

    if (error.status === 400) {
        title = "Not found"
        message = "Could not found resource or page"
    }

    return <>
        <PageContent title={title}>
            <p>{message}</p>
        </PageContent>
    </>
}

export default ErrorEventPage