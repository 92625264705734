import { createBrowserRouter, RouterProvider } from "react-router-dom";

import HomePage from "./pages/HomePage";
import RootPage from "./pages/root/RootPage";
import UsersPage from "./pages/user/UsersPage";
import LoginPage from "./pages/login/LoginPage";
import ShopsPage from "./pages/ShopsPage";
import ReviewsPage from "./pages/ReviewsPage";
import ErrorEvent from "./pages/ErrorEvent";

const router = createBrowserRouter([
  {
    path: '/',
    element: <RootPage/>,
    errorElement: <ErrorEvent/>,
    id: 'root',
    children: [
      {
        path: '',
        element: <HomePage/>
      },
      {
        path: '/users',
        element: <UsersPage/>
      },
      {
        path: '/shops',
        element: <ShopsPage/>
      },
      {
        path: '/reviews',
        element: <ReviewsPage/>
      },
    ]
  }
])

const routerUnLoggedIn = createBrowserRouter([
  {
    path:'',
    element: <LoginPage/>
  }
])

const App = () => {
  return (
    <>
      {<RouterProvider router={localStorage.getItem("token") ? router : routerUnLoggedIn}></RouterProvider>}
    </>
  );
};

export default App;

