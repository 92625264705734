import { createSlice } from '@reduxjs/toolkit'

const initialValues = {
    id: {value: '', isValid: null},
    name: {value: '', isValid: null},
    phone: {value: '', isValid: null},
    address: {value: '', isValid: null},
    openDays: {value: '', isValid: null},
}

const shopDetailsPopupSlice = createSlice({
    name: 'form',
    initialState: initialValues,
    reducers: {
        setShopName: (state, action) => {
            state.name.value = action.payload
            state.name.isValid = action.payload.length > 2
        },
        setShopPhone: (state, action) => {
            state.phone.value = action.payload
        },
        setShopAddress: (state, action) => {
            state.address.value = action.payload
            state.address.isValid = action.payload.length > 2
        },
        setShopOpenDays: (state, action) => {
            state.openDays.value = action.payload
            state.openDays.isValid = action.payload.length > 2
        },
        setShopId: (state, action) => {
            state.id.value = action.payload
        },
        validateShopForm: (state, action) => {
            const name = action.payload.name.value
            const address = action.payload.address.value

            state.name.isValid = name.length > 2
            state.address.isValid = address.length > 2
        },
        resetShopForm: (state) => initialValues
    }
})

export const {setShopOpenDays, setShopId, resetShopForm, setShopAddress, setShopName, validateShopForm, setShopPhone} = shopDetailsPopupSlice.actions
export const getShopPopupForm = (state) => state.shopDetailsPopup; // defined in configureStore({reducer: {userDetailsPopup}});
export default shopDetailsPopupSlice.reducer
