import {createSlice} from '@reduxjs/toolkit'

const initialValues = {
    id: '',
    name: '',
    surname: '',
    email: '',
    role: '',
    phone: ''

}

const reviewUserSlice = createSlice({
    name: 'form',
    initialState: initialValues,
    reducers: {
        setReviewUserNamePopup: (state, action) => {
            state.name = action.payload
        },
        setReviewUserSurnamePopup: (state, action) => {
            state.surname = action.payload
        },
        setReviewUserEmailPopup: (state, action) => {
            state.email = action.payload
        },
        setReviewUserIdPopup: (state, action) => {
            state.id = action.payload
        },
        setReviewUserRolePopup: (state, action) => {
            state.role = action.payload
        },
        setReviewUserPhonePopup: (state, action) => {
            state.phone = action.payload
        },
        resetUserReviewPopup: (state) => initialValues
    }
})

export const {
    setReviewUserNamePopup,
    resetUserReviewPopup,
    setReviewUserEmailPopup,
    setReviewUserIdPopup,
    setReviewUserSurnamePopup,
    setReviewUserRolePopup,
    setReviewUserPhonePopup
} = reviewUserSlice.actions
export const getReviewUserPopup = (state) => state.reviewUserPopUp; // defined in configureStore({reducer: {userDetailsPopup}});
export default reviewUserSlice.reducer
