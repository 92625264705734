import { createSlice } from '@reduxjs/toolkit'

const initialValues = {
  id: {value: '', isValid: null},
  name: {value: '', isValid: null},
  surname: {value: '', isValid: null},
  email: {value: '', isValid: null},
  phone: {value: '', isValid: null},
  role: {value: 'user', isValid: null},
  active: {value: false, isValid: null},
  password: {value: '', isValid: null},
  shopID: {value: '', isValid: null},
  locked: {value: false, isValid: null},
  emailVerified: {value: false, isValid: null},
  failedLoginCount: {value: '', isValid: null},
}

const userDetailsPopupSlice = createSlice({
  name: 'form',
  initialState: initialValues,
  reducers: {
    setName: (state, action) => {
      state.name.value = action.payload
    },
    setSurname: (state, action) => {
      state.surname.value = action.payload
    },
    setEmail: (state, action) => {
      state.email.value = action.payload
      state.email.isValid = action.payload.length > 4 && action.payload.includes('@') && action.payload.includes('.')
    },
    setPhone: (state, action) => {
      state.phone.value = action.payload
    },
    setRole: (state, action) => {
      state.role.value = action.payload
      state.role.isValid = action.payload.length > 2
    },
    setActive: (state, action) => {
      state.active.value = action.payload
    },
    setPassword: (state, action) => {
      state.password.value = action.payload
      state.password.isValid = action.payload.length > 5
    },
    setShopID: (state, action) => {
      state.shopID.value = action.payload
    },
    setLocked: (state, action) => {
      state.locked.value = action.payload
    },
    setEmailVerified: (state, action) => {
      state.emailVerified.value = action.payload
    },
    setFailedLoginCount: (state, action) => {
      state.failedLoginCount.value = action.payload
    },
    setId: (state, action) => {
      state.id.value = action.payload
    },
    validateForm: (state, action) => {
      const email = action.payload.email.value
      const password = action.payload.password.value

      state.email.isValid = email.length > 4 && email.includes('@') && email.includes('.')
      state.password.isValid = password.length > 5
    },
    resetForm: (state) => initialValues
  }
})

export const {setName, setSurname, setEmail, setRole, setPhone, setPassword, resetForm, setActive, setId, validateForm, setShopID, setLocked, setEmailVerified, setFailedLoginCount} = userDetailsPopupSlice.actions
export const getPopupForm = (state) => state.userDetailsPopup; // defined in configureStore({reducer: {userDetailsPopup}});
export default userDetailsPopupSlice.reducer
