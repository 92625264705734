import React, { useEffect, useState } from 'react';
import { usersStyle } from '../users/UsersStyle';
import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {asyncSearchReviews, getAllReviews, setReviewsUsedBySearchForm} from "../../store/reviews/reviewsSlice";
import {
    getReviewSearchForm,
    resetReviewSearchForm,
    setReviewActiveSearchForm, setReviewCreatedFromSearchForm, setReviewCreatedToSearchForm,
    setReviewDescriptionSearchForm, setReviewIdSearchForm,
    setReviewRankSearchForm, setReviewSimpleId, setReviewUpdatedFromSearchForm, setReviewUpdatedToSearchForm,
    setReviewUserIdSearchForm
} from "../../store/reviews/reviewsSearchFormSlice";

const ReviewsSearchForm = () => {
    const dispatch = useDispatch()
    const cssClasses = usersStyle

    const form = useSelector(getReviewSearchForm)
    const [isFormSubmitted, setIsFormSubmitted] = useState(false)
    const [query, setQuery] = useState('')

    const onIdChanged = e => dispatch(setReviewIdSearchForm(e.target.value))
    const onDescriptionChanged = e => dispatch(setReviewDescriptionSearchForm(e.target.value))
    const onRankChanged = e => dispatch(setReviewRankSearchForm(e.target.value))
    const onUserIdChanged = e => dispatch(setReviewUserIdSearchForm(e.target.value))
    const onSimpleIdChanged = e => dispatch(setReviewSimpleId(e.target.value))
    const onActiveChanged = e => dispatch(setReviewActiveSearchForm(e.target.value))
    const onCreatedToChanged = e => dispatch(setReviewCreatedToSearchForm(e.target.value))
    const onCreatedFromChanged = e => dispatch(setReviewCreatedFromSearchForm(e.target.value))
    const onUpdatedFromChanged = e => dispatch(setReviewUpdatedFromSearchForm(e.target.value))
    const onUpdatedToChanged = e => dispatch(setReviewUpdatedToSearchForm(e.target.value))

    const onReset = () => dispatch(resetReviewSearchForm())

    useEffect(() => {
        console.log("useEffect when isFormSubmitted: ", isFormSubmitted)
        if (isFormSubmitted) {
            let queryData = ''
            if (query !== '') {
                queryData = '?' + query.substring(1)
            }

            dispatch(asyncSearchReviews(queryData));
            dispatch(setReviewsUsedBySearchForm())
            setIsFormSubmitted(false)
            setQuery('')
        }
    }, [isFormSubmitted])

    useEffect(() => {
        console.log("Opened User Page")
        dispatch(asyncSearchReviews(''));
        dispatch(setReviewsUsedBySearchForm())
    }, [dispatch])

    function addParamToQuery(paramName, paramValue) {
        if (paramValue !== '') {
            setQuery(prevState => prevState + '&' + paramName + '=' + paramValue)
        }
    }

    const submitHandler = (event) => {
        event.preventDefault()
        addParamToQuery('_id', form.id);
        addParamToQuery('description', form.description);
        addParamToQuery('rank', form.rank);
        addParamToQuery('user', form.userId);
        addParamToQuery('id', form.simpleId);
        addParamToQuery('active', form.active.trim());
        addParamToQuery('createdFrom', form.createdFrom.trim())
        addParamToQuery('createdTo', form.createdTo.trim())
        addParamToQuery('updatedFrom', form.updatedFrom.trim())
        addParamToQuery('updatedTo', form.updatedTo.trim())

        setIsFormSubmitted(true)
    }

    return (
        <>
            <Box sx={{padding: '2rem'}}>
                <h1>Search for a review!</h1>
                <form onSubmit={submitHandler}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={2.4}>
                            <TextField fullWidth id="id" label="Id" name="id" margin="normal" variant="outlined"
                                       value={form.id}
                                       onChange={onIdChanged}/>
                        </Grid>
                        <Grid item xs={12} md={2.4}>
                            <TextField fullWidth id="description" label="Description" name="description" margin="normal" variant="outlined"
                                       value={form.description}
                                       onChange={onDescriptionChanged}/>
                        </Grid>
                        <Grid item xs={12} md={2.4}>
                            <TextField fullWidth id="simpleId" label="Simple Id" name="simpleId" margin="normal" variant="outlined"
                                       value={form.simpleId}
                                       onChange={onSimpleIdChanged}/>
                        </Grid>
                        <Grid item xs={12} md={2.4}>
                            <FormControl fullWidth margin="normal" variant="outlined">
                                <InputLabel id="demo-simple-select-label">Active</InputLabel>
                                <Select labelId="demo-simple-select-label" id="demo-simple-select" label="Active"
                                        value={form.active}
                                        onChange={onActiveChanged}>
                                    <MenuItem value={' '}>All</MenuItem>
                                    <MenuItem value={'true'}>Active</MenuItem>
                                    <MenuItem value={'false'}>Not Active</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={2.4}>
                            <TextField fullWidth id="rank" label="Rank" name="rank" margin="normal" variant="outlined"
                                       type={'number'}
                                       value={form.rank}
                                       onChange={onRankChanged}/>
                        </Grid>
                        <Grid item xs={12} md={2.4}>
                            <TextField fullWidth id="userId" label="User Id" name="userId" margin="normal" variant="outlined"
                                       value={form.userId}
                                       onChange={onUserIdChanged}/>
                        </Grid>
                        <Grid item xs={12} md={2.4}>
                            <TextField fullWidth id="createdFrom" label={'Created From'} name="createdFrom" margin="normal"
                                       variant="outlined"
                                       type={'date'}
                                       InputLabelProps={{shrink : true}}
                                       value={form.createdFrom}
                                       onChange={onCreatedFromChanged}
                            />
                        </Grid>
                        <Grid item xs={12} md={2.4}>
                            <TextField fullWidth id="updatedFrom" label="Updated From"  name="updatedFrom" margin="normal"
                                       variant="outlined"
                                       type={'date'}
                                       InputLabelProps={{shrink : true}}
                                       value={form.updatedFrom}
                                       onChange={onUpdatedFromChanged}>
                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={2.4}>
                            <TextField id="value" label="Created To"  name="createdTo" margin="normal"
                                       variant="outlined"
                                       type={'date'}
                                       InputLabelProps={{shrink : true}}
                                       value={form.createdTo}
                                       onChange={onCreatedToChanged}>
                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={2.4}>
                            <TextField id="value" label="Updated To"  name="updatedTo" margin="normal"
                                       variant="outlined"
                                       type={'date'}
                                       InputLabelProps={{shrink : true}}
                                       value={form.updatedTo}
                                       onChange={onUpdatedToChanged}>
                            </TextField>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item>
                            <Button type="submit" variant="contained" color="primary"
                                    className={cssClasses.submitButton}>Submit</Button>
                        </Grid>
                        <Grid item>
                            <Button variant="contained" color="primary"
                                    className={cssClasses.submitButton}
                                    onClick={onReset}>Cancel</Button>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </>
    );
};

export default ReviewsSearchForm;