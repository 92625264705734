import React, { useEffect, useState } from 'react';

import moment from 'moment'
import { Box, Button } from '@mui/material';
import { Add, DeleteForever, Edit, Pageview } from "@mui/icons-material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ShopDetailsPopup from './ShopDetailsPopup'
import TablePagination from "@material-ui/core/TablePagination";
import { CircularProgress, TableSortLabel } from "@material-ui/core";
import {useDispatch, useSelector} from 'react-redux';
import ConfirmDeletePopup from "./ConfirmDeletePopup";
import { StyledTableCell, StyledTableRow } from "../_common/TableStyle";
import { LOADING } from "../../store/storeStates";
import { ADD, EDIT, VIEW } from "../../config/PopupType";
import {
    asyncSearchShop,
    getAllShops,
    getShopStatus,
    getShopUsedBy,
    setUsedByHomeShop
} from "../../store/shops/shopsSlice";
import {USED_BY_SEARCH} from "../../store/users/userStoreConst";
import AllElements from "../_common/AllElements";

const ShopsTable = () => {
    const [showShopDetailsPopup, setShowShopDetailsPopup] = useState(false)
    const [popupType, setPopupType] = useState('')
    const [showConfirmDeletePopup, setConfirmDeletePopup] = useState(false)
    const dispatch = useDispatch()

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(3);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');

    const shops = useSelector(getAllShops)
    const shopsStatus = useSelector(getShopStatus)
    const [shopsTable, setShopsTable] = useState([]) // we can remove this. ofr now is serving only for ordering
    const [selectedUser, setSelectedUser] = useState({})
    const usedBy = useSelector(getShopUsedBy)
    console.log(usedBy)

    useEffect(() => {
        dispatch(asyncSearchShop());
        dispatch(setUsedByHomeShop())
    },[])

    useEffect(() => {
        setShopsTable(shops)
    }, [shops])

    const onShowUserClick = (row) => showDataOnPopup(row, VIEW)
    const onEditUserClick = (row) => showDataOnPopup(row, EDIT)
    const onAddUserClick = () => showDataOnPopup({}, ADD)

    function showDataOnPopup(selectedUserToShow, popupType) {
        setSelectedUser(selectedUserToShow)
        setPopupType(popupType)
        setShowShopDetailsPopup(true);
    }

    const onCloseShopDetailsPopup = () => setShowShopDetailsPopup(false)

    const onDeleteUserIcon = (row) => {
        setSelectedUser(row)
        setConfirmDeletePopup(true);
    };

    const onCloseConfirmDeletePopup = () => setConfirmDeletePopup(false)

    //will be replaced by MUI
    const onPageChange = (event, newPage) => {
        setPage(newPage);
    };

    //will be replaced by MUI
    const onRowsPerPageChange = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    //will be replaced by MUI
    const handleSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);

        const copyOfDataUsers = [...shopsTable]
        let sorted = copyOfDataUsers.sort((a, b) => {
            if (a[property] !== undefined && b[property] !== undefined) {
                if (isAsc) {
                    return a[property].toString().localeCompare(b[property].toString());
                } else {
                    return b[property].toString().localeCompare(a[property].toString());
                }
            }
        });
        setShopsTable(sorted);
    };

    return (
        <>
            <ShopDetailsPopup open={showShopDetailsPopup} data={selectedUser} onClose={onCloseShopDetailsPopup} popupType={popupType}/>
            <ConfirmDeletePopup open={showConfirmDeletePopup} data={selectedUser} onClose={onCloseConfirmDeletePopup}/>
            {/*<ErrorPopup open={showErrorPopup} error={errorMessage} onClose={onCloseErrorPopup} />*/}

            <Box sx={{padding: '2rem'}} className="tableResult">
                <Button onClick={onAddUserClick} startIcon={<Add/>}> Add User </Button>
                {(shopsStatus === LOADING  && usedBy === USED_BY_SEARCH) && <CircularProgress/>}
                <TableContainer component={Paper}>
                    <Table sx={{minWidth: 700}} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align="right">
                                    <TableSortLabel style={{color: 'white'}} active={orderBy === '_id'}
                                                    direction={order}
                                                    onClick={() => handleSort('_id')}>ID</TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    <TableSortLabel style={{color: 'white'}} active={orderBy === 'name'}
                                                    direction={order}
                                                    onClick={() => handleSort('name')}>Name</TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    <TableSortLabel style={{color: 'white'}} active={orderBy === 'phoneNumber'}
                                                    direction={order}
                                                    onClick={() => handleSort('phoneNumber')}>Phone</TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    <TableSortLabel style={{color: 'white'}} active={orderBy === 'address'}
                                                    direction={order}
                                                    onClick={() => handleSort('address')}>Address</TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    <TableSortLabel style={{color: 'white'}} active={orderBy === 'openDays'}
                                                    direction={order}
                                                    onClick={() => handleSort('openDays')}>Open Days</TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell align="right">Actions</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {shopsTable?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                                <React.Fragment key={row._id}>
                                    <StyledTableRow key={row._id}>
                                        <StyledTableCell component="th" scope="row">{row._id}</StyledTableCell>
                                        <StyledTableCell align="right">{row.name}</StyledTableCell>
                                        <StyledTableCell align="right">{row.phoneNumber}</StyledTableCell>
                                        <StyledTableCell align="right">{row.address}</StyledTableCell>
                                        <StyledTableCell align="right">{row.openDays}</StyledTableCell>
                                        <StyledTableCell align='right'>
                                            <Button variant="outlined" startIcon={<DeleteForever/>}
                                                    onClick={() => onDeleteUserIcon(row)}/>
                                            <Button variant="outlined" startIcon={<Edit/>}
                                                    onClick={() => onEditUserClick(row)}/>
                                            <Button variant="outlined" startIcon={<Pageview/>}
                                                    onClick={() => onShowUserClick(row)}/>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                </React.Fragment>
                            ))}
                        </TableBody>
                    </Table>
                    <TablePagination rowsPerPageOptions={[5, 10, 50, 100]} component="div"
                                     count={shopsTable ? shopsTable.length : 0} rowsPerPage={rowsPerPage}
                                     page={page} onPageChange={onPageChange}
                                     onRowsPerPageChange={onRowsPerPageChange}/>
                </TableContainer>
                <AllElements count={shops.length}/>
            </Box>
        </>
    );
};

export default ShopsTable;