import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Header from "./Header";
import Sidebar from "./Sidebar";
import menuConfig from "./NavBar/menuConfig";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    toolbar: theme.mixins.toolbar,
}));

function MainLayout({children}) {
    const [sideBarOpen, setSideBarOpen] = useState(true)
    const classes = useStyles();

    function handleChangeRequestNavDrawer() {
        setSideBarOpen(!sideBarOpen)
    }

    return (
        <div className={classes.root}>
            <Header styles={{paddingLeft: 236}} classes={classes} handleChangeRequestNavDrawer={handleChangeRequestNavDrawer}/>
            <Sidebar classes={classes} menus={menuConfig.menus} sideBarOpen={sideBarOpen}/>
            <main>
                <div className={classes.toolbar}/>
                {children}
            </main>
        </div>
    );
}

export default MainLayout;