import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { USED_BY_POPUP, USED_BY_SEARCH } from "../users/userStoreConst";
import { FAILED, IDLE, LOADING, SUCCEEDED } from "../storeStates";
import {addReview, deleteReviw, editReview, getReviews} from "../../api/ReviewsApi";

export const asyncSearchReviews = createAsyncThunk('asyncSearchReviews', async (queryData, {rejectWithValue}) => {
    const response = await getReviews(queryData)
    const data = await response.json();
    console.log(data, "DATA ")
    if (!response.ok) {
        console.error("Response not ok.", response)
        return rejectWithValue(response.statusText)
    } else {
        return await data
    }
});

export const asyncDeleteReview = createAsyncThunk('asyncDeleteReview', async (reviewId, {rejectWithValue}) => {
    const response = await deleteReviw(reviewId);
    if (!response.ok) {
        console.error("Response not ok.", response)
        return rejectWithValue(response.statusText)
    } else {
        return reviewId
    }
});

export const asyncEditReview = createAsyncThunk('asyncEditReview', async (review, {rejectWithValue}) => {
    const {id, dataUpdated} = review
    const response = await editReview(id, dataUpdated)
    if (!response.ok) {
        console.error("Response not ok.", response)
        return rejectWithValue(response.statusText)
    } else {
        return await response.json()
    }
});

export const asyncAddReview = createAsyncThunk('asyncAddReview', async (review, {rejectWithValue}) => {
    const response = await addReview(review);
    if (!response.ok) {
        console.error("Response not ok.", response)
        return rejectWithValue(response.statusText)
    } else {
        return await response.json()
    }
});

const reviewsSlice = createSlice({
    name: 'users',
    initialState: {
        data: [],
        status: IDLE,
        error: null,
        usedBy: '' //usedBy
    },
    reducers: {
        setReviewsUsedBySearchForm: (state, action) => {
            state.usedBy = USED_BY_SEARCH
        },
        setReviewsUsedByPopu: (state, action) => {
            state.usedBy = USED_BY_POPUP
        },
        resetReviewsUsedBy: (state, action) => {
            state.error = null
            state.status = 'idle'
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(asyncSearchReviews.pending, (state) => {
                state.status = LOADING;
            })
            .addCase(asyncSearchReviews.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.data = action.payload;
            })
            .addCase(asyncSearchReviews.rejected, (state, action) => {
                state.status = FAILED;
                state.error = action.payload;
            })
            .addCase(asyncDeleteReview.pending, (state) => {
                state.status = LOADING;
            })
            .addCase(asyncDeleteReview.fulfilled, (state, action) => {
                state.status = SUCCEEDED;
                state.data = state.data.filter((review) => review._id !== action.payload);
            })
            .addCase(asyncDeleteReview.rejected, (state, action) => {
                state.status = FAILED;
                state.error = action.payload;
            })
            .addCase(asyncEditReview.pending, (state) => {
                state.status = LOADING;
            })
            .addCase(asyncEditReview.fulfilled, (state, action) => {
                state.status = SUCCEEDED;
                const updatedUser = action.payload;
                const index = state.data.findIndex((user) => user._id === updatedUser._id);
                if (index !== -1) {
                    state.data.splice(index, 1, updatedUser);
                }
            })
            .addCase(asyncEditReview.rejected, (state, action) => {
                state.status = FAILED;
                console.log(action)
                state.error = action.payload;
            })
            .addCase(asyncAddReview.pending, (state) => {
                state.status = LOADING;
            })
            .addCase(asyncAddReview.fulfilled, (state, action) => {
                state.status = SUCCEEDED;
                state.data.push(action.payload);
            })
            .addCase(asyncAddReview.rejected, (state, action) => {
                state.status = FAILED;
                state.error = action.payload;
            });
    },
});

export const {setReviewsUsedBySearchForm, resetReviewsUsedBy, setReviewsUsedByPopu} = reviewsSlice.actions;
export const getAllReviews = (state) => state.reviews.data; // defined in configureStore({reducer: {users}});
export const getReviewsStatus = (state) => state.reviews.status;
export const getReviewsError = (state) => state.reviews.error;
export const getReviewsUsedBy = (state) => state.reviews.usedBy
export default reviewsSlice.reducer;