import React from 'react';
import {Logout, People, Dashboard, Shop, Reviews, ShoppingBasket, ShoppingCart, ShoppingBag, Payments} from "@mui/icons-material";

const menuConfig = {
    menus: [
        { text: 'Dashboard', icon: <Dashboard/>, link: '/' },
        { text: 'Users', icon: <People/>, link: '/users' },
        { text: 'Shops', icon: <Shop/>, link: '/shops' },
        { text: 'Reviews', icon: <Reviews/>, link: '/reviews' },
        { text: 'Logout', icon: <Logout/>, link: '/' },
    ]
};

export default menuConfig;
