import React, {useEffect, useState} from 'react';
import './Login.css'
import {getAuthError, getAuthStatus, login} from "../../store/auth/authSlice";
import {useDispatch, useSelector} from "react-redux";
import {getReviewsError, getReviewsStatus} from "../../store/reviews/reviewsSlice";
import {FAILED, SUCCEEDED} from "../../store/storeStates";
import toastr from "toastr";
import {metaModel} from "../../config/metaModel";
import {Helmet} from "react-helmet";

const LoginPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loginSubmitted, setLoginSubmitted] = useState(false)
    const [rememberMe, setRememberMe] = useState(false);
    const dispatch = useDispatch()

    const authStatus = useSelector(getAuthStatus)
    const authError = useSelector(getAuthError)

    metaModel.title = 'Login Page'
    metaModel.description = "This page has the login of the application"
    metaModel.keywords = 'This is the text for meta keyword'

    useEffect(() => {
        if (authStatus === SUCCEEDED) {
            toastr.info("Operation completed successfully")
        } else if (authStatus === FAILED) {
            toastr.error("Error: " + authError)
        }
    }, [authStatus])

    useEffect(() => {
        const loginInfo = JSON.parse(localStorage.getItem('loginInfo'));

        if (loginInfo) {
            setEmail(loginInfo.email);
            setPassword(loginInfo.password);
            setRememberMe(true);
        }
    }, []);

    useEffect(() => {
        if (loginSubmitted) {
            const data = {
                email,
                password
            }
            dispatch(login(data))
            setEmail('')
            setPassword('')
            setLoginSubmitted(false)
        }
    }, [loginSubmitted])

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoginSubmitted(true)
        if (rememberMe) {
            localStorage.setItem('loginInfo', JSON.stringify({ email, password }));
        } else {
            localStorage.removeItem('loginInfo');
        }
    };

    const onEmailChange = (e) => setEmail(e.target.value)

    const onPasswordChange = (event) => setPassword(event.target.value)

    const handleRememberMeChange = (event) => setRememberMe(event.target.checked);

    return (
        <div className="login-container">
            <Helmet>
                <title> {metaModel.title}</title>
                <meta name="description" content={metaModel.description}/>
                <meta name="keywords" content={metaModel.keywords}/>
            </Helmet>
            <h2>Login</h2>
            <form onSubmit={handleSubmit}>
                <label>
                    Email:
                    <input
                        type="email"
                        value={email}
                        onChange={onEmailChange}
                    />
                </label>
                <label>
                    Password:
                    <input
                        type="password"
                        value={password}
                        onChange={onPasswordChange}
                    />
                </label>
                <label htmlFor="rememberMe">
                    <input type="checkbox" id="rememberMe" checked={rememberMe} onChange={handleRememberMeChange} />
                    Remember me
                </label>
                <button type="submit">Log In</button>
            </form>
        </div>
    );
};

export default LoginPage;