import React, {useEffect, useState} from 'react';

import moment from 'moment'
import {metaModel} from "../../config/metaModel";
import {Box, Button} from '@mui/material';
import {Add, DeleteForever, Edit, Pageview} from "@mui/icons-material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from "@material-ui/core/TablePagination";
import {CircularProgress, TableSortLabel} from "@material-ui/core";
import {useSelector} from 'react-redux';
import {Checkbox} from "@mui/material";
import {StyledTableCell, StyledTableRow} from "../_common/TableStyle";
import {USED_BY_SEARCH} from "../../store/users/userStoreConst";
import {LOADING} from "../../store/storeStates";
import {ADD, EDIT, VIEW} from "../../config/PopupType";
import {getAllReviews, getReviewsStatus, getReviewsUsedBy} from "../../store/reviews/reviewsSlice";
import ReviewDetailsPopup from "./ReviewDetailsPopup";
import ConfirmDeletePopup from "./ConfirmDeletePopup";
import ReviewUserPopup from "./ReviewUserPopup";
import AllElements from "../_common/AllElements";

const ReviewsTable = () => {
    const [showUserDetailsPopup, setShowUserDetailsPopup] = useState(false)
    const [popupType, setPopupType] = useState('')
    const [showConfirmDeletePopup, setConfirmDeletePopup] = useState(false)
    const [userData, setUserDaata] = useState(false)

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(3);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');

    const reviews = useSelector(getAllReviews)
    const reviewsStatus = useSelector(getReviewsStatus)
    const usedBy = useSelector(getReviewsUsedBy)
    const [reviewsTable, setReviewsTable] = useState([]) // we can remove this. ofr now is serving only for ordering
    const [selectedUser, setSelectedUser] = useState({})

    useEffect(() => {
        setReviewsTable(reviews.reviews)
    }, [reviews])

    const onShowUserClick = (row) => showDataOnPopup(row, VIEW)
    const onEditUserClick = (row) => showDataOnPopup(row, EDIT)
    const onAddUserClick = () => showDataOnPopup({}, ADD)

    function showDataOnPopup(selectedUserToShow, popupType) {
        setSelectedUser(selectedUserToShow)
        setPopupType(popupType)
        setShowUserDetailsPopup(true);
    }

    const onCloseUserDetailsPopup = () => setShowUserDetailsPopup(false)

    const onUserDetails = (user) => {
        setSelectedUser(user)
        setUserDaata(true)
    }

    const onCloseUserDetails = () => {
        setUserDaata(false)
    }

    const onDeleteUserIcon = (row) => {
        setSelectedUser(row)
        setUserDaata(true);
    };

    const onCloseConfirmDeletePopup = () => setConfirmDeletePopup(false)

    //will be replaced by MUI
    const onPageChange = (event, newPage) => {
        setPage(newPage);
    };

    //will be replaced by MUI
    const onRowsPerPageChange = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    //will be replaced by MUI
    const handleSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);

        const copyOfDataReviews = [...reviewsTable]
        let sorted = copyOfDataReviews.sort((a, b) => {
            if (a[property] !== undefined && b[property] !== undefined) {
                if (isAsc) {
                    return a[property].toString().localeCompare(b[property].toString());
                } else {
                    return b[property].toString().localeCompare(a[property].toString());
                }
            }
        });
        setReviewsTable(sorted);
    };

    return (
        <>
            <ReviewDetailsPopup open={showUserDetailsPopup} data={selectedUser} onClose={onCloseUserDetailsPopup}
                                popupType={popupType}/>
            <ConfirmDeletePopup open={showConfirmDeletePopup} data={selectedUser} onClose={onCloseConfirmDeletePopup}/>
            <ReviewUserPopup open={userData} data={selectedUser} onClose={onCloseUserDetails}/>
            {/*<ErrorPopup open={showErrorPopup} error={errorMessage} onClose={onCloseErrorPopup} />*/}

            <Box sx={{padding: '2rem'}} className="tableResult">
                {/*<Button onClick={onAddUserClick} startIcon={<Add/>}> Add Review </Button>*/}
                {(reviewsStatus === LOADING && usedBy === USED_BY_SEARCH) && <CircularProgress/>}
                <TableContainer component={Paper}>
                    <Table sx={{minWidth: 700}} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align="right">
                                    <TableSortLabel style={{color: 'white'}} active={orderBy === '_id'}
                                                    direction={order}
                                                    onClick={() => handleSort('_id')}>ID</TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    <TableSortLabel style={{color: 'white'}} active={orderBy === 'description'}
                                                    direction={order}
                                                    onClick={() => handleSort('description')}>Description</TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    <TableSortLabel style={{color: 'white'}} active={orderBy === 'rank'}
                                                    direction={order}
                                                    onClick={() => handleSort('rank')}>Rank</TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    <TableSortLabel style={{color: 'white'}} active={orderBy === 'user'}
                                                    direction={order}
                                                    onClick={() => handleSort('user')}>User</TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    <TableSortLabel style={{color: 'white'}} active={orderBy === 'id'}
                                                    direction={order}
                                                    onClick={() => handleSort('id')}>Simple Id</TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    <TableSortLabel style={{color: 'white'}} active={orderBy === 'active'}
                                                    direction={order}
                                                    onClick={() => handleSort('active')}>Active</TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    <TableSortLabel style={{color: 'white'}} active={orderBy === 'createdAt'}
                                                    direction={order}
                                                    onClick={() => handleSort('createdAt')}>Created At</TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell align="right">Actions</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {reviewsTable?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                                <React.Fragment key={row._id}>
                                    <StyledTableRow key={row._id}>
                                        <StyledTableCell component="th" scope="row">{row._id}</StyledTableCell>
                                        <StyledTableCell align="right">{row.description}</StyledTableCell>
                                        <StyledTableCell align="right">{row.rank}</StyledTableCell>
                                        <StyledTableCell align="right">
                                            {row.user ? <a href="#" onClick={() => onUserDetails(row.user)}>
                                                {row.user.email}
                                            </a> : ''}

                                        </StyledTableCell>
                                        <StyledTableCell align="right">{row.id}</StyledTableCell>
                                        <StyledTableCell align="right">
                                            <Checkbox
                                                checked={row.active}
                                                disabled
                                                inputProps={{'aria-label': 'controlled'}}
                                            />
                                        </StyledTableCell>
                                        <StyledTableCell
                                            align="right">{moment(row.createdAt).format('YYYY-MM-DD')}</StyledTableCell>
                                        <StyledTableCell align='right'>
                                            <Button variant="outlined" startIcon={<DeleteForever/>}
                                                    onClick={() => onDeleteUserIcon(row)}/>
                                            <Button variant="outlined" startIcon={<Edit/>}
                                                    onClick={() => onEditUserClick(row)}/>
                                            <Button variant="outlined" startIcon={<Pageview/>}
                                                    onClick={() => onShowUserClick(row)}/>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                </React.Fragment>
                            ))}
                        </TableBody>
                    </Table>
                    <TablePagination rowsPerPageOptions={[5, 10, 50, 100]} component="div"
                                     count={reviewsTable ? reviewsTable.length : 0} rowsPerPage={rowsPerPage}
                                     page={page} onPageChange={onPageChange}
                                     onRowsPerPageChange={onRowsPerPageChange}/>
                </TableContainer>
                <AllElements count={reviews.count} />
            </Box>
        </>
    );
};

export default ReviewsTable;