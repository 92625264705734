import React from "react";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import {IconButton} from "@material-ui/core";
import {Menu} from "@mui/icons-material";
import {MenuOpen} from "@mui/icons-material";

const Header = ({classes, styles, handleChangeRequestNavDrawer}) => {
    return (
        <>
            <AppBar position="fixed"  style={{...styles}}>
                <Toolbar>
                    <IconButton onClick={handleChangeRequestNavDrawer} >
                    <MenuOpen/>
                    </IconButton>

                    {/* The header of your page goes here */}
                </Toolbar>
            </AppBar>
        </>
    )
}

export default Header