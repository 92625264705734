import {useEffect} from "react";
import {useSelector} from 'react-redux'
import toastr from "toastr";
import {Helmet} from 'react-helmet'
import {metaModel} from "../config/metaModel";
import ShopsTable from "../compontents/shops/ShopsTable";
import ReviewsSearchForm from "../compontents/reviews/ReviewsSearchForm";
import ReviewsTable from "../compontents/reviews/ReviewsTable";
import {getReviewsError, getReviewsStatus} from "../store/reviews/reviewsSlice";
import {FAILED, SUCCEEDED} from "../store/storeStates";

const ReviewsPage = () => {
    const reviewsStatus = useSelector(getReviewsStatus)
    const reviewsError = useSelector(getReviewsError)

    useEffect(() => {
        if (reviewsStatus === SUCCEEDED) {
            toastr.info("Operation completed successfully")
        } else if (reviewsStatus === FAILED) {
            toastr.error("Error: " + reviewsError)
        }
    }, [reviewsStatus])

    metaModel.title = 'Reviews Page'
    metaModel.description = "This page has all reviews of the application"
    metaModel.keywords = 'This is the text for meta keyword'

    return (
        <>
            <Helmet>
                <title> {metaModel.title}</title>
                <meta name="description" content={metaModel.description}/>
                <meta name="keywords" content={metaModel.keywords}/>
            </Helmet>
            <h1>The Review Page</h1>
            <ReviewsSearchForm/>
            <ReviewsTable/>
        </>
    )
}

export default ReviewsPage