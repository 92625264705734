import {useEffect} from "react";
import {useSelector} from 'react-redux'
import toastr from "toastr";
import {Helmet} from 'react-helmet';
import SearchForm from "../../compontents/users/SearchForm";
import UsersTable from "../../compontents/users/UsersTable";
import {FAILED, SUCCEEDED} from "../../store/storeStates";
import {getError, getStatus} from "../../store/users/usersSlice";
import {metaModel} from "../../config/metaModel";

const UsersPage = () => {
    const usersStatus = useSelector(getStatus)
    const usersError = useSelector(getError)

    useEffect(() => {
        if (usersStatus === SUCCEEDED) {
            toastr.info("Operation completed successfully")
        } else if (usersStatus === FAILED) {
            toastr.error("Error: " + usersError)
        }
    }, [usersStatus])

    metaModel.title = 'Users Page'
    metaModel.description = "This page has all users of the application"
    metaModel.keywords = 'This is the text for meta keyword'

    return (
        <>
            <Helmet>
                <title> {metaModel.title}</title>
                <meta name="description" content={metaModel.description}/>
                <meta name="keywords" content={metaModel.keywords}/>
            </Helmet>
            <SearchForm/>
            <UsersTable/>
        </>
    )
}

export default UsersPage