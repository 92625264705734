import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import {
  Backdrop,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from "@mui/material";
import { CircularProgress } from "@material-ui/core";
import {
  asyncAddUser,
  asyncEditUser,
  getAllUsers,
  getStatus,
  getUsedBy,
  resetUsedBy,
  setUsedByPopup
} from '../../store/users/usersSlice';
import { getPopupForm, resetForm, setEmail, setId, setName, setPassword, setPhone, setRole, setSurname, validateForm, setActive, setShopID, setEmailVerified, setLocked, setFailedLoginCount } from '../../store/users/userDetailsPopupSlice';
import { USED_BY_POPUP } from "../../store/users/userStoreConst";
import { FAILED, LOADING, SUCCEEDED } from "../../store/storeStates";
import { ADD, EDIT, VIEW } from "../../config/PopupType";
import {getShops as shop} from "../../api/ShopApi";

const UserDetailsPopup = ({open, data, onClose, popupType}) => {
  const [isValidForm, setIsValidForm] = useState(false)
  const dispatch = useDispatch();
  const popupForm = useSelector(getPopupForm)
  const users = useSelector(getAllUsers)
  const usersStatus = useSelector(getStatus)
  const usedBy = useSelector(getUsedBy)
  const [shops, setShops] = useState([])

  const onNameChanged = e => dispatch(setName(e.target.value))
  const onSurnameChanged = e => dispatch(setSurname(e.target.value))
  const onPhoneChanged = e => dispatch(setPhone(e.target.value))
  const onEmailChanged = e => dispatch(setEmail(e.target.value))
  const onRoleChanged = e => dispatch(setRole(e.target.value))
  const onPasswordChanged = e => dispatch(setPassword(e.target.value))
  const onShopIDChanged = e => dispatch(setShopID(e.target.value))
  const onLockedChanged = e => dispatch(setLocked(e.target.checked))
  const onActiveChanged = e => dispatch(setActive(e.target.checked))
  const onEmailVerifiedChanged = e => dispatch(setEmailVerified(e.target.checked))
  const onFailedLoginCount = e => dispatch(setFailedLoginCount(e.target.value))

  const getDataShops = async () => {
    let data = await shop()
    data = await data.json()
    setShops(data)
  }

  useEffect(() => {
    if (open) {
     getDataShops()
    }
    if (open && (popupType === VIEW || popupType === EDIT)) {
      dispatch(setId(data._id))
      dispatch(setName(data.name))
      dispatch(setSurname(data.surname))
      dispatch(setEmail(data.email))
      dispatch(setPhone(data.phone))
      dispatch(setRole(data.role))
      dispatch(setPassword(data.password))
      dispatch(setShopID(data.shopId))
      dispatch(setLocked(data.locked))
      dispatch(setActive(data.active))
      dispatch(setEmailVerified(data.emailVerified))
      dispatch(setFailedLoginCount(data.failedLoginCount))
    }
  }, [open])

  useEffect(() => {
    if (isValidForm && (popupType === ADD || popupType === EDIT)) {
      console.log("UserDetailsPopup useEffect for isValidForm, users.status. isValidForm: ", isValidForm, ",  users.status:", users.status, ", popupType: ", popupType)
      const dataUpdated = {
        name: popupForm.name.value,
        surname: popupForm.surname.value,
        phone: popupForm.phone.value,
        email: popupForm.email.value,
        role: popupForm.role.value,
        failedLoginCount: popupForm.failedLoginCount.value,
        emailVerified: popupForm.emailVerified.value,
        locked: popupForm.locked.value,
        active: popupForm.active.value,
        password: popupForm.password.value,
        shopId: popupForm.shopID.value
      }
      const id = popupForm.id.value
      if (popupType === EDIT) {
        const userData = {id, dataUpdated}
        dispatch(asyncEditUser(userData));
      } else {
        dispatch(asyncAddUser(dataUpdated))
      }
      dispatch(setUsedByPopup())
      setIsValidForm(false)
    }
  }, [isValidForm])


  useEffect(() => {
    if (usersStatus === SUCCEEDED) {
      onClosePopup()
    }
  }, [usersStatus])

  const onSubmit = (event) => {
    event.preventDefault()
    console.debug("onSubmit popupForm: ", popupForm)
    dispatch(validateForm(popupForm))
    const validForm = popupForm.email.isValid && popupForm.password.isValid
    console.debug("onSubmit validForm: ", validForm)
    setIsValidForm(validForm)
  }

  const onClosePopup = () => {
    onClose()
    dispatch(resetForm())
    dispatch(resetUsedBy())
  }

  return (
    <Dialog open={open} onClose={onClosePopup}
            BackdropComponent={Backdrop} BackdropProps={{invisible: true}}>
      <DialogTitle>User Details</DialogTitle>
      {usersStatus === LOADING && usedBy === USED_BY_POPUP && <CircularProgress/>}
      <DialogContent>
        <DialogContentText>User Details below</DialogContentText>
        {(popupType === VIEW || popupType === EDIT) &&
        <TextField fullWidth id="idPopup" label="Id" name="Id" margin="normal" variant="outlined"
                   disabled
                   value={popupForm.id.value}/>
        }
        <TextField fullWidth id="namePopup" label="Name" name="name" margin="normal" variant="outlined"
                   disabled={popupType === VIEW}
                   value={popupForm.name.value}
                   onChange={onNameChanged}/>
        <TextField fullWidth id="surnamePopup" label="Surname" name="surname" margin="normal" variant="outlined"
                   disabled={popupType === VIEW}
                   value={popupForm.surname.value}
                   onChange={onSurnameChanged}/>
        <TextField fullWidth id="emailPopup" label="Email Address" name="email" margin="normal" variant="outlined"
                   disabled={popupType === VIEW}
                   value={popupForm.email.value}
                   onChange={onEmailChanged}
                   error={popupType !== VIEW && popupForm.email.isValid !== null ? !popupForm.email.isValid : false}
                   helperText={popupType !== VIEW && popupForm.email.isValid !== null && !popupForm.email.isValid ? 'Email should contain "@", "." and have 4 chars' : ''}/>
        <TextField fullWidth id="phonePopup" label="Phone Number" name="phone" margin="normal" variant="outlined"
                   disabled={popupType === VIEW}
                   value={popupForm.phone.value}
                   onChange={onPhoneChanged}/>
        {(popupType === ADD || popupType === EDIT) &&
        <TextField fullWidth type={"password"} id="password"
                   label="Password" name="password" margin="normal" variant="outlined"
                   required={popupType === ADD}
                   onChange={onPasswordChanged}
                   error={popupForm.password.isValid !== null ? !popupForm.password.isValid : false}
                   helperText={popupForm.password.isValid !== null && !popupForm.password.isValid ? 'Password is required to be at least 6 characters ' : ''}/>
        }

        <FormControl fullWidth margin="normal" variant="outlined"
                     error={popupType !== VIEW && popupForm.role.isValid !== null ? !popupForm.role.isValid : false}
                     helpertext={popupType !== VIEW && popupForm.role.isValid !== null && !popupForm.role.isValid ? 'Role is required to be at user or admin' : ''}>
          <InputLabel id="demo-simple-select-label">Role</InputLabel>
          <Select labelId="demo-simple-select-label" id="rolePopup" label="Role"
                  value={popupForm.role.value}
                  onChange={onRoleChanged} disabled={popupType === VIEW}>
            <MenuItem value={'admin'}>Admin</MenuItem>
            <MenuItem value={'user'}>User</MenuItem>
          </Select>
          {popupType !== VIEW && popupForm.role.isValid !== null && !popupForm.role.isValid &&
          <FormHelperText>Please select an option between user or admin</FormHelperText>
          }
        </FormControl>

        {shops.length > 0 && <FormControl fullWidth margin="normal" variant="outlined">
          <InputLabel id="shopIdSection">Shop</InputLabel>
          <Select labelId="ShopId" id="shopPopup" label="Shop"
                  value={popupForm.shopID.value}
                  onChange={onShopIDChanged} disabled={popupType === VIEW}>
            {shops.map((shop) => (
                <MenuItem key={shop._id} value={shop._id}>{shop.name}</MenuItem>
            ))}
          </Select>
        </FormControl>}
        <TextField fullWidth id="failedLoginCount" label="Failed Login" name="failedLoginCount" margin="normal" variant="outlined"
                   disabled={popupType === VIEW || popupType === EDIT}
                   value={popupForm.failedLoginCount.value}
                   onChange={onFailedLoginCount}/>
        <div>
          <Checkbox
              checked={popupForm.locked.value}
              onChange={onLockedChanged}
              disabled={popupType === VIEW || popupType === EDIT}
              inputProps={{'aria-label': 'controlled'}}
          />
          <label>Locked</label>
        </div>
        <div>
          <Checkbox
              checked={popupForm.active.value}
              onChange={onActiveChanged}
              disabled={popupType === VIEW}
              inputProps={{'aria-label': 'controlled'}}
          />
          <label>Active</label>
        </div>
        <div>
          <Checkbox
              checked={popupForm.emailVerified.value}
              onChange={onEmailVerifiedChanged}
              disabled={popupType === VIEW || popupType === EDIT}
              inputProps={{'aria-label': 'controlled'}}
          />
          <label>Email Verified</label>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClosePopup}>Cancel</Button>
        {popupType !== VIEW &&
        <Button onClick={onSubmit}>Save</Button>
        }
      </DialogActions>
    </Dialog>
  )
}

export default UserDetailsPopup