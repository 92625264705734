import { API_URL } from "../config/env";
const resourceName = "/users";
const register = '/register'
const loginResource = '/login'
const token = localStorage.getItem('token')

export const searchUser = async (queryData) => {
  const url = `${API_URL}${resourceName}${queryData}`;
  console.log("Calling url: ", url)
  try {
    const response = await fetch(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: 'Bearer ' + token
      }
    });
    return response;
  } catch (error) {
    console.error("Failed calling backend searchUser: ", url, "Error:", error)
    throw error;
  }
};

export const searchOneUser = async (id) => {
  const url = `${API_URL}${resourceName}/${id}`;
  console.log("Calling url: ", url)
  try {
    const response = await fetch(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: 'Bearer ' + token
      }
    });
    return response;
  } catch (error) {
    console.error("Failed calling backend searchUser: ", url, "Error:", error)
    throw error;
  }
};

export const editUser = async (id, userData) => {
  const url = `${API_URL}${resourceName}/${id}`;
  console.log("Calling url: ", url, "BODY:", JSON.stringify(userData))
  try {
    const response = await fetch(url, {
      method: 'PUT',
      body: JSON.stringify(userData),
      headers: {
        "Content-Type": "application/json",
        Authorization: 'Bearer ' + token
      }
    });
    return response;
  } catch (error) {
    console.error("Failed calling backend editUser: ", url, "Error:", error)
    throw error;
  }
};

export const addUser = async (userData) => {
  const url = `${API_URL}${register}`;
  console.log("Calling url: ", url, "BODY:", JSON.stringify(userData))
  try {
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(userData),
      headers: {
        "Content-Type": "application/json",
        Authorization: 'Bearer ' + token
      }
    });
    return response;
  } catch (error) {
    console.error("Failed calling backend addUser: ", url, "Error:", error)
    throw error;
  }
};

export const deleteUser = async (id) => {
  const url = `${API_URL}${resourceName}/${id}`;
  console.log("Calling url: ", url)
  try {
    const response = await fetch(url, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    })
    const data = await response;
    return await data;
  } catch (error) {
    console.error("Failed calling backend deleteUser: ", url, "Error:", error)
    throw error;
  }
};

export const login = async (userData) => {
  const url = `${API_URL}${loginResource}`;
  console.log("Calling url: ", url, "BODY:", JSON.stringify(userData))
  try {
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(userData),
      headers: {
        "Content-Type": "application/json",
        Authorization: 'Bearer ' + token
      }
    });
    return response;
  } catch (error) {
    console.error("Failed calling backend editUser: ", url, "Error:", error)
    throw error;
  }
};

export const logout = () => {
  localStorage.removeItem("token");
};
