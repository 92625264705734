import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import {
    Backdrop,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from "@mui/material";
import { CircularProgress } from "@material-ui/core";
import {
    asyncAddUser,
    asyncEditUser,
    getAllUsers,
    getStatus,
    getUsedBy,
    resetUsedBy,
    setUsedByPopup
} from '../../store/users/usersSlice';
import { getPopupForm, resetForm, setEmail, setId, setName, setPassword, setPhone, setRole, setSurname, validateForm, setActive, setShopID, setEmailVerified, setLocked, setFailedLoginCount } from '../../store/users/userDetailsPopupSlice';
import { USED_BY_POPUP } from "../../store/users/userStoreConst";
import { FAILED, LOADING, SUCCEEDED } from "../../store/storeStates";
import { ADD, EDIT, VIEW } from "../../config/PopupType";
import {getShops as shop} from "../../api/ShopApi";
import {
    getReviewPopupForm, resetReviewForm,
    setReviewActive,
    setReviewDescription, setReviewId,
    setReviewRank,
    setReviewSimpleId,
    setReviewUserId, validateReviewForm
} from "../../store/reviews/reviewDetailsPopupSlice";
import {
    asyncAddReview,
    asyncEditReview,
    getAllReviews,
    getReviewsStatus, getReviewsUsedBy, resetReviewsUsedBy,
    setReviewsUsedByPopu
} from "../../store/reviews/reviewsSlice";

const ReviewDetailsPopup = ({open, data, onClose, popupType}) => {
    const [isValidForm, setIsValidForm] = useState(false)
    const dispatch = useDispatch();
    const popupForm = useSelector(getReviewPopupForm)
    const users = useSelector(getAllReviews)
    const usersStatus = useSelector(getReviewsStatus)
    const usedBy = useSelector(getReviewsUsedBy)

    const onDescriptionChanged = e => dispatch(setReviewDescription(e.target.value))
    const onRankChanged = e => dispatch(setReviewRank(e.target.value))
    const onUserIdChanged = e => dispatch(setReviewUserId(e.target.value))
    const onSimpleIdChanged = e => dispatch(setReviewSimpleId(e.target.value))
    const onActiveChanged = e => dispatch(setReviewActive(e.target.checked))

    useEffect(() => {
        if (open && (popupType === VIEW || popupType === EDIT)) {
            dispatch(setReviewId(data._id))
            dispatch(setReviewSimpleId(data.id))
            dispatch(setReviewRank(data.rank))
            dispatch(setReviewUserId(data.user))
            dispatch(setReviewActive(data.active))
            dispatch(setReviewDescription(data.description))
        }
    }, [open])

    useEffect(() => {
        if (isValidForm && (popupType === ADD || popupType === EDIT)) {
            console.log("UserDetailsPopup useEffect for isValidForm, users.status. isValidForm: ", isValidForm, ",  users.status:", users.status, ", popupType: ", popupType)
            const dataUpdated = {
                description: popupForm.description.value,
                rank: popupForm.rank.value,
                id: popupForm.simpleId.value,
                user: popupForm.userId.value,
                active: popupForm.active.value
            }
            const id = popupForm.id.value
            if (popupType === EDIT) {
                const userData = {id, dataUpdated}
                dispatch(asyncEditReview(userData));
            } else {
                dispatch(asyncAddReview(dataUpdated))
            }
            dispatch(setReviewsUsedByPopu())
            setIsValidForm(false)
        }
    }, [isValidForm])


    useEffect(() => {
        if (usersStatus === SUCCEEDED) {
            onClosePopup()
        }
    }, [usersStatus])

    const onSubmit = (event) => {
        event.preventDefault()
        console.debug("onSubmit popupForm: ", popupForm)
        dispatch(validateReviewForm(popupForm))
        const validForm = popupForm.userId.isValid
        console.debug("onSubmit validForm: ", validForm)
        setIsValidForm(validForm)
    }

    const onClosePopup = () => {
        onClose()
        dispatch(resetReviewForm())
        dispatch(resetReviewsUsedBy())
    }

    return (
        <Dialog open={open} onClose={onClosePopup}
                BackdropComponent={Backdrop} BackdropProps={{invisible: true}}>
            <DialogTitle>Review Details</DialogTitle>
            {usersStatus === LOADING && usedBy === USED_BY_POPUP && <CircularProgress/>}
            <DialogContent>
                <DialogContentText>User Details below</DialogContentText>
                {(popupType === VIEW || popupType === EDIT) &&
                    <TextField fullWidth id="idPopup" label="Id" name="Id" margin="normal" variant="outlined"
                               disabled
                               value={popupForm.id.value}/>
                }
                <TextField fullWidth id="descriptionPopup" label="Description" name="description" margin="normal" variant="outlined"
                           disabled={popupType === VIEW}
                           multiline
                           rows={10}
                           value={popupForm.description.value}
                           onChange={onDescriptionChanged}/>
                <TextField fullWidth id="rankPopup" label="Rank" name="rank" margin="normal" variant="outlined"
                           disabled={popupType === VIEW}
                           value={popupForm.rank.value}
                           onChange={onRankChanged}/>
                <TextField fullWidth id="sipmpleIdPopup" label="Simple Id" name="simpleId" margin="normal" variant="outlined"
                           disabled={true}
                           value={popupForm.simpleId.value}
                           onChange={onSimpleIdChanged}/>
                <TextField fullWidth id="userIdPopup" label="User Id" name="userId" margin="normal" variant="outlined"
                           disabled={popupType === VIEW || popupType === EDIT}
                           value={popupForm.userId.value}
                           onChange={onUserIdChanged}
                           error={popupType !== VIEW && popupForm.userId.isValid !== null ? !popupForm.userId.isValid : false}
                           helperText={popupType !== VIEW && popupForm.userId.isValid !== null && !popupForm.userId.isValid ? 'UserID should have 2 chars' : ''}/>
                <div>
                    <Checkbox
                        checked={popupForm.active.value}
                        onChange={onActiveChanged}
                        disabled={popupType === VIEW}
                        inputProps={{'aria-label': 'controlled'}}
                    />
                    <label>Active</label>
                </div>

                <TextField fullWidth id="createdAt" label="Created At" name="userId" margin="normal" variant="outlined"
                           disabled={true}
                           value={data?.createdAt}/>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClosePopup}>Cancel</Button>
                {popupType !== VIEW &&
                    <Button onClick={onSubmit}>Save</Button>
                }
            </DialogActions>
        </Dialog>
    )
}

export default ReviewDetailsPopup