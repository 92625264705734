import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { Link } from 'react-router-dom'
import {logout, setLogut} from "../../../store/auth/authSlice";
import {useDispatch} from "react-redux";

function Sidebar(props) {
    const {classes, menus, sideBarOpen} = props
    const dispatch = useDispatch()

    const handleLogout = () => {
        dispatch(logout())
        window.location.assign('/')
    }

    return (
        <Drawer
            className={classes.drawer}
            variant="persistent" // permanent
            anchor="left"
            open={sideBarOpen}
            classes={{
                paper: classes.drawerPaper,
            }}
        >
            <div className={classes.toolbar} />
            <List>
                    {menus.map((menu) => (
                        <ListItem button key={menu.text}  component={menu.text !== "Logout" ? Link : null} to={menu.text !== "Logout" ? menu.link : ''}>
                            <ListItemIcon>
                                {menu.icon}
                            </ListItemIcon>
                            {menu.text === "Logout" ?
                                <ListItemText onClick={handleLogout} primary={menu.text}></ListItemText>
                             :
                                <ListItemText primary={menu.text}></ListItemText>
                            }
                        </ListItem>
                    ))}
            </List>
        </Drawer>
    );
}

export default Sidebar;

