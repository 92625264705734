import { makeStyles } from "@material-ui/core/styles/index";

export const usersStyle = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(4),
  },
  table: {
    minWidth: 650,
  },
  form: {
    width: '100%',
    maxWidth: 400,
    marginTop: theme.spacing(3),
  },
  submitButton: {
    margin: theme.spacing(3, 0, 2),
  },
  formControl: {
    minWidth: 120,
  },

}));