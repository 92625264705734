import { createSlice } from '@reduxjs/toolkit'

const initialValues = {
    id: {value: '', isValid: null},
    description: {value: '', isValid: null},
    rank: {value: '', isValid: null},
    userId: {value: '', isValid: null},
    simpleId: {value: '', isValid: null},
    active: {value: false, isValid: null},
}

const reviewDetailsPopupSlice = createSlice({
    name: 'form',
    initialState: initialValues,
    reducers: {
        setReviewDescription: (state, action) => {
            state.description.value = action.payload
        },
        setReviewRank: (state, action) => {
            state.rank.value = action.payload
        },
        setReviewUserId: (state, action) => {
            state.userId.value = action.payload
            state.userId.isValid = action.payload.length > 2
        },
        setReviewSimpleId: (state, action) => {
            state.simpleId.value = action.payload
        },
        setReviewActive: (state, action) => {
            state.active.value = action.payload
        },
        setReviewId: (state, action) => {
            state.id.value = action.payload
        },
        validateReviewForm: (state, action) => {
            const userId = action.payload.userId.value

            state.userId.isValid = userId.length > 2
        },
        resetReviewForm: (state) => initialValues
    }
})

export const {setReviewId, resetReviewForm, setReviewActive, setReviewDescription, setReviewRank, setReviewSimpleId, setReviewUserId, validateReviewForm} = reviewDetailsPopupSlice.actions
export const getReviewPopupForm = (state) => state.reviewDetailsPopup; // defined in configureStore({reducer: {userDetailsPopup}});
export default reviewDetailsPopupSlice.reducer
