import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import {
    Backdrop,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from "@mui/material";
import { ADD, EDIT, VIEW } from "../../config/PopupType";
import {
    getReviewUserPopup, resetUserReviewPopup, setReviewUserEmailPopup,
    setReviewUserIdPopup,
    setReviewUserNamePopup, setReviewUserPhonePopup, setReviewUserRolePopup,
    setReviewUserSurnamePopup
} from "../../store/reviews/reviewUserSlice";

const ReviewUserPopup = ({open, data, onClose}) => {
    const dispatch = useDispatch();
    const popupForm = useSelector(getReviewUserPopup)

    useEffect(() => {
        if (open) {
            dispatch(setReviewUserIdPopup(data._id))
            dispatch(setReviewUserSurnamePopup(data.surname))
            dispatch(setReviewUserNamePopup(data.name))
            dispatch(setReviewUserEmailPopup(data.email))
            dispatch(setReviewUserRolePopup(data.role))
            dispatch(setReviewUserPhonePopup(data.phone))
        }
    }, [open])


    const onClosePopup = () => {
        onClose()
        dispatch(resetUserReviewPopup())
    }

    return (
        <Dialog open={open} onClose={onClosePopup}
                BackdropComponent={Backdrop} BackdropProps={{invisible: true}}>
            <DialogTitle>Review User Details</DialogTitle>
            <DialogContent>
                <DialogContentText>User Details below</DialogContentText>

                <TextField fullWidth id="descriptionPopup" label="Description" name="description" margin="normal" variant="outlined"
                           disabled={true}
                           value={popupForm?.id}
                           />
                <TextField fullWidth id="name" label="Name" name="name" margin="normal" variant="outlined"
                           disabled={true}
                           value={popupForm?.name}
                />
                <TextField fullWidth id="surname" label="Surname" name="surname" margin="normal" variant="outlined"
                           disabled={true}
                           value={popupForm?.surname}
                          />
                <TextField fullWidth id="email" label="Email" name="email" margin="normal" variant="outlined"
                           disabled={true}
                           value={popupForm?.email}
                           />
                <TextField fullWidth id="hone" label="Phone" name="phone" margin="normal" variant="outlined"
                           disabled={true}
                           value={popupForm?.phone}
                />
                <TextField fullWidth id="role" label="Role" name="role" margin="normal" variant="outlined"
                           disabled={true}
                           value={popupForm?.role}
                />

            </DialogContent>
            <DialogActions>
                <Button onClick={onClosePopup}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}

export default ReviewUserPopup