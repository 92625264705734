import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import {
    Backdrop,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField
} from "@mui/material";
import { CircularProgress } from "@material-ui/core";

import { FAILED, LOADING, SUCCEEDED } from "../../store/storeStates";
import { ADD, EDIT, VIEW } from "../../config/PopupType";
import {
    asyncAddShop,
    asyncEditShop,
    getAllShops,
    getShopStatus,
    getShopUsedBy, resetShopUsedBy,
    setUsedByShopPopup
} from "../../store/shops/shopsSlice";
import {
    getShopPopupForm, resetShopForm,
    setShopAddress, setShopId,
    setShopName,
    setShopOpenDays,
    setShopPhone, validateShopForm
} from "../../store/shops/shopDetailsPopupSlice";
import {USED_BY_POPUP} from "../../store/users/userStoreConst";

const ShopDetailsPopup = ({open, data, onClose, popupType}) => {
    const [isValidForm, setIsValidForm] = useState(false)
    const dispatch = useDispatch();
    const popupForm = useSelector(getShopPopupForm)
    const shops = useSelector(getAllShops)
    const shopsStatus = useSelector(getShopStatus)
    const usedBy = useSelector(getShopUsedBy)

    const onNameChanged = e => dispatch(setShopName(e.target.value))
    const onAddressChanged = e => dispatch(setShopAddress(e.target.value))
    const onPhoneChanged = e => dispatch(setShopPhone(e.target.value))
    const onOpenDaysChanged = e => dispatch(setShopOpenDays(e.target.value))

    useEffect(() => {
        if (open && (popupType === VIEW || popupType === EDIT)) {
            dispatch(setShopId(data._id))
            dispatch(setShopName(data.name))
            dispatch(setShopAddress(data.address))
            dispatch(setShopOpenDays(data.openDays))
            dispatch(setShopPhone(data.phoneNumber))
        }
    }, [open])

    useEffect(() => {
        if (isValidForm && (popupType === ADD || popupType === EDIT)) {
            console.log("UserDetailsPopup useEffect for isValidForm, shops.status. isValidForm: ", isValidForm, ",  shops.status:", shops.status, ", popupType: ", popupType)
            const dataUpdated = {
                name: popupForm.name.value,
                address: popupForm.address.value,
                phoneNumber: popupForm.phone.value,
                openDays: popupForm.openDays.value,
            }
            const id = popupForm.id.value
            if (popupType === EDIT) {
                const shopData = {id, dataUpdated}
                dispatch(asyncEditShop(shopData));
            } else {
                dispatch(asyncAddShop(dataUpdated))
            }
            dispatch(setUsedByShopPopup())
            setIsValidForm(false)
        }
    }, [isValidForm])


    useEffect(() => {
        if (shopsStatus === SUCCEEDED) {
            onClosePopup()
        }
    }, [shopsStatus])

    const onSubmit = (event) => {
        event.preventDefault()
        console.debug("onSubmit popupForm: ", popupForm)
        dispatch(validateShopForm(popupForm))
        const validForm = popupForm.name.isValid && popupForm.address.isValid
        console.debug("onSubmit validForm: ", validForm)
        setIsValidForm(validForm)
    }

    const onClosePopup = () => {
        onClose()
        dispatch(resetShopForm())
        dispatch(resetShopUsedBy())
    }
    console.log(popupForm.name)

    return (
        <Dialog open={open} onClose={onClosePopup}
                BackdropComponent={Backdrop} BackdropProps={{invisible: true}}>
            <DialogTitle>Shop Details</DialogTitle>
            {shopsStatus === LOADING && usedBy === USED_BY_POPUP && <CircularProgress/>}
            <DialogContent>
                <DialogContentText>User Details below</DialogContentText>
                {(popupType === VIEW || popupType === EDIT) &&
                    <TextField fullWidth id="idPopup" label="Id" name="Id" margin="normal" variant="outlined"
                               disabled
                               value={popupForm.id.value}/>
                }
                <TextField fullWidth id="namePopup" label="Name" name="name" margin="normal" variant="outlined"
                           disabled={popupType === VIEW}
                           value={popupForm.name.value}
                           error={popupType !== VIEW && popupForm.name.isValid !== null ? !popupForm.name.isValid : false}
                           helperText={popupType !== VIEW && popupForm.name.isValid !== null && !popupForm.name.isValid ? 'Name should have 2 chars' : ''}
                           onChange={onNameChanged}/>
                <TextField fullWidth id="addressPopup" label="address" name="address" margin="normal" variant="outlined"
                           disabled={popupType === VIEW}
                           value={popupForm.address.value}
                           error={popupType !== VIEW && popupForm.address.isValid !== null ? !popupForm.address.isValid : false}
                           helperText={popupType !== VIEW && popupForm.address.isValid !== null && !popupForm.address.isValid ? 'Address should have 4 chars' : ''}
                           onChange={onAddressChanged}/>
                <TextField fullWidth id="openDaysPopup" label="Open Days" name="openDays" margin="normal" variant="outlined"
                           disabled={popupType === VIEW}
                           value={popupForm.openDays.value}
                           onChange={onOpenDaysChanged}/>
                <TextField fullWidth id="phonePopup" label="Phone Number" name="phone" margin="normal" variant="outlined"
                           disabled={popupType === VIEW}
                           value={popupForm.phone.value}
                           onChange={onPhoneChanged}/>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClosePopup}>Cancel</Button>
                {popupType !== VIEW &&
                    <Button onClick={onSubmit}>Save</Button>
                }
            </DialogActions>
        </Dialog>
    )
}

export default ShopDetailsPopup