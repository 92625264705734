import { configureStore } from "@reduxjs/toolkit";
import userSLice from './users/usersSlice'
import userSearchFormSlice from "./users/userSearchFormSlice";
import userDetailsPopupSlice from "./users/userDetailsPopupSlice";
import authSlice from "./auth/authSlice";
import shopsSlice from "./shops/shopsSlice";
import shopDetailsPopupSlice from "./shops/shopDetailsPopupSlice";
import reviewsSlice from "./reviews/reviewsSlice";
import reviewsSearchFormSlice from "./reviews/reviewsSearchFormSlice";
import reviewDetailsPopupSlice from "./reviews/reviewDetailsPopupSlice";

const store = configureStore({
  reducer: {
    userSearchForm: userSearchFormSlice,
    userDetailsPopup: userDetailsPopupSlice,
    users: userSLice,
    auth: authSlice,

    shops: shopsSlice,
    shopDetailsPopup: shopDetailsPopupSlice,

    reviews: reviewsSlice,
    reviewSearchForm: reviewsSearchFormSlice,
    reviewDetailsPopup: reviewDetailsPopupSlice
  }
});

export default store