import React, { useEffect, useState } from 'react';
import { usersStyle } from './UsersStyle';
import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { asyncSearchUser, setUsedBySearchForm } from '../../store/users/usersSlice';
import {
  getSearchForm,
  resetSearchForm,
  setActiveSearchForm, setCreatedFromSearchForm, setCreatedToSearchForm,
  setEmailSearchForm,
  setIdSearchForm,
  setLockedSearchForm,
  setNameSearchForm,
  setPhoneSearchForm,
  setRoleSearchForm,
  setSurnameSearchForm, setUpdatedFromSearchForm, setUpdatedToSearchForm, setVerifiedSearchForm
} from '../../store/users/userSearchFormSlice';

const SearchForm = () => {
  const dispatch = useDispatch()
  const cssClasses = usersStyle

  const form = useSelector(getSearchForm)
  const [isFormSubmitted, setIsFormSubmitted] = useState(false)
  const [query, setQuery] = useState('')

  const onIdChanged = e => dispatch(setIdSearchForm(e.target.value))
  const onNameChanged = e => dispatch(setNameSearchForm(e.target.value))
  const onSurnameChanged = e => dispatch(setSurnameSearchForm(e.target.value))
  const onPhoneChanged = e => dispatch(setPhoneSearchForm(e.target.value))
  const onEmailChanged = e => dispatch(setEmailSearchForm(e.target.value))
  const onRoleChanged = e => dispatch(setRoleSearchForm(e.target.value))
  const onActiveChanged = e => dispatch(setActiveSearchForm(e.target.value))
  const onLockedChanged = e => dispatch(setLockedSearchForm(e.target.value))
  const onVerifiedChanged = e => dispatch(setVerifiedSearchForm(e.target.value))
  const onCreatedToChanged = e => dispatch(setCreatedToSearchForm(e.target.value))
  const onCreatedFromChanged = e => dispatch(setCreatedFromSearchForm(e.target.value))
  const onUpdatedFromChanged = e => dispatch(setUpdatedFromSearchForm(e.target.value))
  const onUpdatedToChanged = e => dispatch(setUpdatedToSearchForm(e.target.value))

  const onReset = () => dispatch(resetSearchForm())

  useEffect(() => {
    console.log("useEffect when isFormSubmitted: ", isFormSubmitted)
    if (isFormSubmitted) {
      let queryData = ''
      if (query !== '') {
        queryData = '?' + query.substring(1)
      }

      dispatch(asyncSearchUser(queryData));
      dispatch(setUsedBySearchForm())
      setIsFormSubmitted(false)
      setQuery('')
    }
  }, [isFormSubmitted])

  useEffect(() => {
    console.log("Opened User Page")
    dispatch(asyncSearchUser(''));
    dispatch(setUsedBySearchForm())
  }, [dispatch])

  function addParamToQuery(paramName, paramValue) {
    if (paramValue !== '') {
      setQuery(prevState => prevState + '&' + paramName + '=' + paramValue)
    }
  }

  console.log(query)

  const submitHandler = (event) => {
    event.preventDefault()
    addParamToQuery('_id', form.id);
    addParamToQuery('name', form.name);
    addParamToQuery('surname', form.surname);
    addParamToQuery('email', form.email);
    addParamToQuery('phone', form.phone);
    addParamToQuery('role', form.role.trim());
    addParamToQuery('active', form.active.trim());
    addParamToQuery('locked', form.locked.trim())
    addParamToQuery('emailVerified', form.emailVerified.trim())
    addParamToQuery('createdFrom', form.createdFrom.trim())
    addParamToQuery('createdTo', form.createdTo.trim())
    addParamToQuery('updatedFrom', form.updatedFrom.trim())
    addParamToQuery('updatedTo', form.updatedTo.trim())

    setIsFormSubmitted(true)
  }

  return (
    <>
      <Box sx={{padding: '2rem'}}>
        <h1>Search for an user!</h1>
        <form onSubmit={submitHandler}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={2.1} sm={4}>
              <TextField fullWidth id="id" label="Id" name="id" margin="normal" variant="outlined"
                         size="small"
                         value={form.id}
                         onChange={onIdChanged}/>
            </Grid>
            <Grid item xs={12} md={2.1} sm={4}>
              <TextField fullWidth id="name" label="Name" name="name" margin="normal" variant="outlined"
                         size="small"
                         value={form.name}
                         onChange={onNameChanged}/>
            </Grid>
            <Grid item xs={12} md={2.1} sm={4}>
              <TextField fullWidth id="surname" label="Surname" name="surname" margin="normal" variant="outlined"
                         size="small"
                         value={form.surname}
                         onChange={onSurnameChanged}/>
            </Grid>
            <Grid item xs={12} md={2.1} sm={4}>
              <FormControl fullWidth margin="normal" variant="outlined">
                <InputLabel id="demo-simple-select-label">Active</InputLabel>
                <Select labelId="demo-simple-select-label" id="demo-simple-select" label="Active"
                        size="small"
                        value={form.active}
                        onChange={onActiveChanged}>
                  <MenuItem value={' '}>All</MenuItem>
                  <MenuItem value={'true'}>Active</MenuItem>
                  <MenuItem value={'false'}>Not Active</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={2.1} sm={4}>
              <FormControl fullWidth margin="normal" variant="outlined">
                <InputLabel id="verified">Verified</InputLabel>
                <Select labelId="demo-simple-select-label" id="demo-simple-select" label="Verified"
                        size="small"
                        value={form.emailVerified}
                        onChange={onVerifiedChanged}>
                  <MenuItem value={' '}>All</MenuItem>
                  <MenuItem value={'true'}>Verified</MenuItem>
                  <MenuItem value={'false'}>Not verified</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={2.1} sm={4}>
              <FormControl fullWidth margin="normal" variant="outlined">
                <InputLabel id="demo-simple-select-label">Role</InputLabel>
                <Select labelId="demo-simple-select-label" id="demo-simple-select" label="Role"
                        size="small"
                        value={form.role}
                        onChange={onRoleChanged}>
                  <MenuItem value={' '}>All</MenuItem>
                  <MenuItem value={'admin'}>Admin</MenuItem>
                  <MenuItem value={'user'}>User</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={2.1} sm={4}>
              <TextField fullWidth id="phone" label="Phone Number" name="phone" margin="normal" variant="outlined"
                         size="small"
                         value={form.phone}
                         onChange={onPhoneChanged}/>
            </Grid>
            <Grid item xs={12} md={2.1} sm={4}>
              <TextField fullWidth id="email" label="Email Address" name="email" margin="normal" variant="outlined"
                         size="small"
                         value={form.email}
                         onChange={onEmailChanged}/>
            </Grid>
            <Grid item xs={12} md={2.1} sm={4}>
              <FormControl fullWidth margin="normal" variant="outlined">
                <InputLabel id="locked">Locked</InputLabel>
                <Select labelId="demo-simple-select-label" id="demo-simple-select" label="Locked"
                        size="small"
                        value={form.locked}
                        onChange={onLockedChanged}>
                  <MenuItem value={' '}>All</MenuItem>
                  <MenuItem value={'true'}>Locked</MenuItem>
                  <MenuItem value={'false'}>Unlocked</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={2.1} sm={4}>
              <div></div>
            </Grid>
            <Grid item xs={12} md={2.1} sm={4}>
              <TextField  id="createdFrom" size="small" label={'Created From'} name="createdFrom"
                          margin="normal"
                          variant="outlined"
                          type={'date'}
                          sx={{ width: '100%' }}
                          InputLabelProps={{shrink : true}}
                          value={form.createdFrom}
                          onChange={onCreatedFromChanged}
              />
            </Grid>
            <Grid item xs={12} md={2.1} sm={4}>
              <TextField  id="updatedFrom" size="small" label="Updated From" name="updatedFrom"
                          margin="normal"
                          type={'date'}
                          sx={{ width: '100%' }}
                          InputLabelProps={{shrink : true}}
                          value={form.updatedFrom}
                          onChange={onUpdatedFromChanged}>
              </TextField>
            </Grid>
            <Grid item xs={12} md={2.1} sm={4}>
              <TextField id="value" label="Created To" size="small" name="createdTo" margin="normal"
                         variant="outlined"
                         type={'date'}
                         InputLabelProps={{shrink : true}}
                         sx={{ width: '100%' }}
                         value={form.createdTo}
                         onChange={onCreatedToChanged}>
              </TextField>
            </Grid>
            <Grid item xs={12} md={2.1} sm={4}>
              <TextField id="value" label="Updated To"  size="small" name="updatedTo" margin="normal"
                         variant="outlined"
                         type={'date'}
                         InputLabelProps={{shrink : true}}
                         sx={{ width: '100%' }}
                         value={form.updatedTo}
                         onChange={onUpdatedToChanged}>
              </TextField>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item>
              <Button type="submit" variant="contained" color="primary"
                      className={cssClasses.submitButton}>Submit</Button>
            </Grid>
            <Grid item>
              <Button variant="contained" color="primary"
                      className={cssClasses.submitButton}
                      onClick={onReset}>Cancel</Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
};

export default SearchForm;